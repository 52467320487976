import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select as ChakraSelect,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../../../Components/Navbar";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Select from "react-select";
import countryList from "react-select-country-list";
import axios from "axios";
import { useAuth } from "../../../Context/AuthProvider";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { backendBaseURL } from "../../../AppConstants";
import { MdDeleteForever } from "react-icons/md";
import { format } from "date-fns";
const EditShipmentDetails = () => {
  const location = useLocation();
  const initialContract = location.state?.contract;

  // Use state to store contract data
  const [contract, setContract] = useState(initialContract);
  console.log("normalcontract", contract);
  const navigate = useNavigate();
  const ButtonStyle = {
    size: "xs",
    fontWeight: "bold",
    backgroundColor: "white",
    _hover: { backgroundColor: "white" },
  };

  const [view, setView] = useState("shipmentdetailsform");
  const navigation = useNavigate();

  const {
    itemNamesList,
    bankNamesList,
    clearingAgentsList,
    shippingLinesList,
    transporterNamesList,
    paginationData,
  } = useAuth();

  const FormLabelStyle = {
    color: "gray",
    fontSize: "12px",
  };

  const InputStyle = {
    width: { base: "100%", md: "70%", lg: "500px" },
    focusBorderColor: "teal.400",
    borderColor: "gray.400",
  };

  const SubInputStyle = {
    width: { base: "100%", md: "70%", lg: "240px" },
    focusBorderColor: "teal.400",
    borderColor: "gray.400",
  };

  const MiniMultiInputStyle = {
    width: { base: "100%", md: "70%", lg: "150px" },
    focusBorderColor: "teal.400",
    borderColor: "gray.400",
  };

  const handleEdit = async (id) => {
    const documentsStatusData = {
      draftStatusFromShipper,
      originalStatusFromShipper,
      draftStatusToClearingAgent,
      originalStatusToClearingAgent,
    };
    try {
      const response = await axios.patch(
        `${backendBaseURL}/editShippingDetails/${id}`,
        {
          shipmentWiseData, // the data you are editing
          rows, // if you are including rows data for the edit
          documentsStatusData,
          rowsShipper,
          rowsDeliver,
          paymentStatusRows,
          paymentStatusData,
          customerClearingStatusData,
          rowsCCS,
          rowsWeightLevel1,
          rowsWeightLevel2,
          rowsWeightLevel3,
          totalsWeightLevel1,
          totalsWeightLevel2,
          totalsWeightLevel3,
          weightSummaryData,
          costing,
        }
      );

      // Check if the update was successful
      if (response.status === 200) {
        alert("Shipping Details Updated Successfully");
navigate("/salescontract");
setTimeout(() => {
  window.location.reload();
}, 3000);
        // Update the contract state with the latest data from the response
        const updatedContract = response.data.users;
        //console.log("updatedContract", updatedContract);
        setContract(updatedContract);
      }
    } catch (error) {
      console.error("Error updating shipping details:", error);
      alert("Failed to update shipping details");
    }
  };

  useEffect(() => {
    const fetchContractData = async () => {
      try {
        const response = await axios.get(
          `${backendBaseURL}/getShippingDetails`
        );
        setContract(response.data);
      } catch (error) {
        console.error("Error fetching contract data:", error);
      }
    };

    if (!contract) {
      fetchContractData();
    }
  }, []);

  // Rendering Each Tabs Start Here //

  const [shipmentWiseData, setShipmentWiseData] = useState({
    exporterName: contract.shipmentWiseData.exporterName,
    hssName: contract.shipmentWiseData.hssName,
    shipperName: contract.shipmentWiseData.shipperName,
    hssSellerInvoiceNo: contract.shipmentWiseData.hssSellerInvoiceNo,
    shipmentcountry: contract.shipmentWiseData.shipmentcountry,
    typeOfDuty: contract.shipmentWiseData.typeOfDuty,
    shippingLine: contract.shipmentWiseData.shippingLine,
    lendingNo: contract.shipmentWiseData.lendingNo,
    eta: contract.shipmentWiseData.eta,
    finalDeliveryPort: contract.shipmentWiseData.finalDeliveryPort,
    clearingHouseAgentName: contract.shipmentWiseData.clearingHouseAgentName,
    quantityInMTS: contract.shipmentWiseData.quantityInMTS,
    exporterInvoiceNo: contract.shipmentWiseData.exporterInvoiceNo,
    exporterInvoiceDate: contract.shipmentWiseData.exporterInvoiceDate,
  });

  const [paymentStatusData, setPaymentStatusData] = useState({
    shipperInvoiceRemarks: contract.paymentStatusData.shipperInvoiceRemarks,
    shipperInvoiceCurrency: contract.paymentStatusData.shipperInvoiceCurrency,
    shipperInvoiceAmount: contract.paymentStatusData.shipperInvoiceAmount,
    otherAmountRemarks: contract.paymentStatusData.otherAmountRemarks,
    otherAmountCurrency: contract.paymentStatusData.otherAmountCurrency,
    otherAmountAmount: contract.paymentStatusData.otherAmountAmount,
    lessAmountRemarks: contract.paymentStatusData.lessAmountRemarks,
    lessAmountCurrency: contract.paymentStatusData.lessAmountCurrency,
    lessAmountAmount: contract.paymentStatusData.lessAmountAmount,
    lessCreditRemarks: contract.paymentStatusData.lessCreditRemarks,
    lessCreditCurrency: contract.paymentStatusData.lessCreditCurrency,
    lessCreditAmount: contract.paymentStatusData.lessCreditAmount,
    lessClaimRemarks: contract.paymentStatusData.lessClaimRemarks,
    lessClaimCurrency: contract.paymentStatusData.lessClaimCurrency,
    lessClaimAmount: contract.paymentStatusData.lessClaimAmount,
    balanceRemarks: contract.paymentStatusData.balanceRemarks,
    balanceCurrency: contract.paymentStatusData.balanceCurrency,
    balanceAmount: contract.paymentStatusData.balanceAmount,
  });
  const [customerClearingStatusData, setCustomerClearingStatusData] = useState({
    checkListReceivedDate:
      contract.customerClearingStatusData.checkListReceivedDate,
    checkListApprovedDate:
      contract.customerClearingStatusData.checkListApprovedDate,
    checkListApprovedBy:
      contract.customerClearingStatusData.checkListApprovedBy,
    beNo: contract.customerClearingStatusData.beNo,
    beDate: contract.customerClearingStatusData.beDate,
    bePaymentDate: contract.customerClearingStatusData.bePaymentDate,
    sealPhotoDate: contract.customerClearingStatusData.sealPhotoDate,
    sealPhotoRemarks: contract.customerClearingStatusData.sealPhotoRemarks,
    cfsWeightDate: contract.customerClearingStatusData.cfsWeightDate,
    cfsWeightRemarks: contract.customerClearingStatusData.cfsWeightRemarks,
    transporterName: contract.customerClearingStatusData.transporterName,
    transporterRateINR: contract.customerClearingStatusData.transporterRateINR,
    dispatchFrom1: contract.customerClearingStatusData.dispatchFrom1,
    dispatchTo1: contract.customerClearingStatusData.dispatchTo1,
    dispatchDate1: contract.customerClearingStatusData.dispatchDate1,
    dispatchFrom2: contract.customerClearingStatusData.dispatchFrom2,
    dispatchTo2: contract.customerClearingStatusData.dispatchTo2,
    dispatchDate2: contract.customerClearingStatusData.dispatchDate2,
  });

  const [value, setValue] = useState("");

  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (selectedOption) => {
    setValue(selectedOption);
    setShipmentWiseData((prevData) => ({
      ...prevData,
      shipmentcountry: selectedOption.label,
    }));
  };

  const handleInputChangeShipmentWise = (e) => {
    const { name, value } = e.target;
    setShipmentWiseData((prevData) => ({ ...prevData, [name]: value }));
    
  };

  // const handleInputChangePaymentStatus = (e) => {
  //   const { name, value } = e.target;
  //   setPaymentStatusData((prevData) => ({ ...prevData, [name]: value }));
  // };

  const calculateTotalInvoiceValue = () => {
    return rows.reduce((acc, row) => acc + Number(row.hssValueUSD || 0), 0);
  };

  const calculateTotalInvoiceValueINR = () => {
    if (shipmentWiseData.typeOfImport === "High Sea Sale Import") {
    return rows.reduce((acc, row) => acc + Number(row.hssValueINR|| 0), 0);
    } else {
      // Default calculation using invoiceValueExporter
      return rows.reduce((acc, row) => acc + Number(row.invoiceValueExporter || 0), 0);
    }
  };
  const handleInputChangePaymentStatus = (e) => {
    const { name, value } = e.target;

    // Update the specific field
    setPaymentStatusData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      // Calculate balanceAmount
      const shipperInvoiceAmount = calculateTotalInvoiceValueINR () || 0;
      const otherAmountAmount = parseFloat(updatedData.otherAmountAmount) || 0;
      const lessAmountAmount = parseFloat(updatedData.lessAmountAmount) || 0;
      const lessCreditAmount = parseFloat(updatedData.lessCreditAmount) || 0;
      const lessClaimAmount = parseFloat(updatedData.lessClaimAmount) || 0;

      const balanceAmount =
      calculateTotalInvoiceValueINR () +
        otherAmountAmount -
        lessAmountAmount -
        lessCreditAmount -
        lessClaimAmount;

      // Calculate balanceAmount
      const shipperInvoiceCurrency = calculateTotalInvoiceValue () || 0;
      const otherAmountCurrency =
        parseFloat(updatedData.otherAmountCurrency) || 0;
      const lessAmountCurrency =
        parseFloat(updatedData.lessAmountCurrency) || 0;
      const lessCreditCurrency =
        parseFloat(updatedData.lessCreditCurrency) || 0;
      const lessClaimCurrency = parseFloat(updatedData.lessClaimCurrency) || 0;

      const balanceCurrency =
      calculateTotalInvoiceValue () +
        otherAmountCurrency -
        lessAmountCurrency -
        lessCreditCurrency -
        lessClaimCurrency;

      return {
        ...updatedData,

        balanceAmount: balanceAmount.toFixed(2),
        balanceCurrency: balanceCurrency.toFixed(2),
        shipperInvoiceCurrency: shipperInvoiceCurrency.toFixed(2),
        shipperInvoiceAmount: shipperInvoiceAmount.toFixed(2),
      };
    });
  };

  const handleInputChangeCustomClearingStatus = (e) => {
    const { name, value } = e.target;
    setCustomerClearingStatusData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [rows, setRows] = useState(
    contract?.rows?.length > 0
      ? contract.rows
      : [
          {
            itemName: "",
            quantity: "",
            hsn: "",
            rate: "",
            exchangeRate: "",
            hssValueUSD: "",
            hssValueINR: "",
            currency: "",
            invoiceValue: "",
            invoiceValueExporter: "",
            difference: "",
          },
        ]
  );

  const calculateTotalShippedQuantity = () => {
    return rows.reduce((total, row) => total + Number(row.quantity || 0), 0);
  };

  const calculateRemainingQuantity = () => {
    const totalQuantity = calculateTotalShippedQuantity();
    return shipmentWiseData.quantityInMTS - totalQuantity;
  };

  const handleInputChangeRows = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rows];
    newRows[index] = { ...newRows[index], [name]: value };
    setRows(newRows);
  };

  const addNewRow = () => {
    setRows([
      ...rows,
      {
        itemName: "",
        quantity: "",
        hsn: "",
        rate: "",
        exchangeRate: "",
        hssValueUSD: "",
        hssValueINR: "",
        currency: "",
        invoiceValue: "",
        difference: "",
      },
    ]);
  };

  const removeNewRow = () => {
    setRows(rows.slice(0, -1));
  };

  const [paymentStatusRows, setPaymentStatusRows] = useState(
    contract?.paymentStatusRows?.length > 0
      ? contract.paymentStatusRows
      : [
          {
            modeOfPayment: "",
            paymentDate: "",
            paymentBankName: "",
            transactionRefNo: "",
            paymentAmount: "",
            paymentCurrency: "",
            exchangeRate: "",
            amountInINR: "",
            bankCharges: "",
            creditPeriod: "",
            paymentDueDate: "",
          },
        ]
  );

  // const handleInputChangePaymentStatusRows = (e, index) => {
  //   const { name, value } = e.target;
  //   const newRows = [...paymentStatusRows];
  //   newRows[index] = { ...newRows[index], [name]: value };
  //   setPaymentStatusRows(newRows);
  // };

  const handleInputChangePaymentStatusRows = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...paymentStatusRows];

    // Update the current field
    newRows[index] = { ...newRows[index], [name]: value };

    // Calculate `amountInINR` if either `paymentAmount` or `exchangeRate` is updated
    const paymentAmount = parseFloat(newRows[index].paymentAmount) || 0;
    const exchangeRate = parseFloat(newRows[index].exchangeRate) || 0;

    // Update `amountInINR` only if both `paymentAmount` and `exchangeRate` are present
    if (name === "paymentAmount" || name === "exchangeRate") {
      newRows[index].amountInINR = (paymentAmount * exchangeRate).toFixed(2);
    }

    if (name === "paymentDate" || name === "creditPeriod") {
      const paymentDate = new Date(newRows[index].paymentDate);
      const creditPeriod = parseInt(newRows[index].creditPeriod) || 0;

      if (!isNaN(paymentDate.getTime()) && creditPeriod > 0) {
        paymentDate.setDate(paymentDate.getDate() + creditPeriod);
        newRows[index].paymentDueDate = paymentDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
      }
    }

    setPaymentStatusRows(newRows);
    // Recalculate `lessAmountAmount`
    const totalLessAmount = newRows.reduce((sum, row) => {
      return sum + (parseFloat(row.amountInINR) || 0);
    }, 0);

    const totalLessAmountUSD = newRows.reduce((sum, row) => {
      return sum + (parseFloat(row.paymentAmount) || 0);
    }, 0);

    setPaymentStatusData((prevData) => ({
      ...prevData,
      lessAmountAmount: totalLessAmount.toFixed(2), // Update `lessAmountAmount`
      lessAmountCurrency: totalLessAmountUSD.toFixed(2), // Update `lessAmountCurrency`
    }));
  };

  const addNewPaymentStatusRow = () => {
    setPaymentStatusRows([
      ...paymentStatusRows,
      {
        modeOfPayment: "",
        paymentDate: "",
        paymentBankName: "",
        transactionRefNo: "",
        paymentAmount: "",
        paymentCurrency: "",
        exchangeRate: "",
        amountInINR: "",
        bankCharges: "",
        creditPeriod: "",
        paymentDueDate: "",
      },
    ]);
  };

  const removeNewPaymentStatusRow = () => {
    if (paymentStatusRows.length > 1) {
      setPaymentStatusRows(paymentStatusRows.slice(0, -1));
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const renderShipmentWise = () => (
    <Box>
      <FormControl>
        <Flex justifyContent={"space-around"} mt={5}>
          {contract?.rows[0]?.exchangeRate === null && (
            <Box>
              <FormLabel {...FormLabelStyle} style={{ color: "green" }}>Exporter Name</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="exporterName"
                value={shipmentWiseData.exporterName || ""}
                onChange={handleInputChangeShipmentWise}
                {...InputStyle}
                //isReadOnly
                focusBorderColor="green"
                borderColor={"green"}
                bgColor={"green.100"}
              />
            </Box>
          )}
          {contract?.rows[0]?.exchangeRate != null && (
            <Box>
              <FormLabel {...FormLabelStyle} style={{ color: "green" }}>HSS Exporter Name</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="hssName"
                value={shipmentWiseData.hssName || ""}
                onChange={handleInputChangeShipmentWise}
                {...InputStyle}
                focusBorderColor="green"
                borderColor={"green"}
                bgColor={"green.100"}
              />
            </Box>
          )}

          <Box {...InputStyle}>
            <FormLabel {...FormLabelStyle}>
              Country Of Origin Selected - {shipmentWiseData.shipmentcountry}
            </FormLabel>
            <Select options={options} value={value} onChange={changeHandler} />
          </Box>
        </Flex>

        {contract?.rows[0]?.exchangeRate != null && (
          <Flex justifyContent={"space-around"} mt={2}>
            <Box>
              <FormLabel {...FormLabelStyle}>Shipper Name</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="shipperName"
                value={shipmentWiseData.shipperName || ""}
                onChange={handleInputChangeShipmentWise}
                {...InputStyle}
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>HSS Seller Invoice No</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="hssSellerInvoiceNo"
                value={contract.shipmentWiseData.hssSellerInvoiceNo || ""}
                onChange={handleInputChangeShipmentWise}
                {...InputStyle}
              />
            </Box>
          </Flex>
        )}

        <Flex justifyContent={"space-around"} mt={2}>
          <Box>
            <FormLabel {...FormLabelStyle} style={{ color: "green" }}>Type Of Duty</FormLabel>
            <ChakraSelect
              name="typeOfDuty"
              value={shipmentWiseData.typeOfDuty || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
              focusBorderColor="green"
              borderColor={"green"}
              bgColor={"green.100"}
            >
              <option value="" disabled>
                Type Of Duty
              </option>
              <option value="Duty-Free">Duty-Free</option>
              <option value="With-Duty">With-Duty</option>
            </ChakraSelect>
          </Box>
          <Box>
            <FormLabel {...FormLabelStyle}>Shipping Line Name</FormLabel>
            <ChakraSelect
              name="shippingLine"
              value={shipmentWiseData.shippingLine || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            >
              <option value="" disabled>
                Shipping Line Name
              </option>
              {shippingLinesList.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </ChakraSelect>
          </Box>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Box>
            <FormLabel {...FormLabelStyle}>Bill Of Lending Number</FormLabel>
            <Input
              type="text"
              placeholder=""
              name="lendingNo"
              value={shipmentWiseData.lendingNo || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            />
          </Box>
          <Box>
            <FormLabel {...FormLabelStyle}>
              Estimated Time of Arrival (ETA)
            </FormLabel>
            <Input
              type="date"
              placeholder=""
              name="eta"
              // value={formatDate(contract?.shipmentWiseData?.eta)}
              value={formatDate(shipmentWiseData?.eta) || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            />
          </Box>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Box>
            <FormLabel {...FormLabelStyle}>Final Delivery Port</FormLabel>
            <Input
              type="text"
              placeholder=""
              name="finalDeliveryPort"
              value={shipmentWiseData.finalDeliveryPort || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            />
          </Box>
          <Box>
            <FormLabel {...FormLabelStyle}>Clearing House Agent Name</FormLabel>

            <ChakraSelect
              name="clearingHouseAgentName"
              value={shipmentWiseData.clearingHouseAgentName || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
            >
              <option value="" disabled>
                Clearing House Agent Name
              </option>
              {clearingAgentsList.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </ChakraSelect>
          </Box>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Box>
            <FormLabel {...FormLabelStyle} style={{ color: "green" }}>Quantity (In MTS)</FormLabel>
            <Input
              type="text"
              placeholder=""
              name="quantityInMTS"
              // value={
              //   contract.shipmentWiseData.quantityInMTS ||
              //   contract.fixedQuantity
              // }
              value={shipmentWiseData.quantityInMTS || ""}
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
              isReadOnly
              focusBorderColor="green"
              borderColor={"green"}
              bgColor={"green.100"}
            />
          </Box>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Exporter Invoice No</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="exporterInvoiceNo"
                value={shipmentWiseData.exporterInvoiceNo || ""}
                onChange={handleInputChangeShipmentWise}
                {...SubInputStyle}
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Invoice Date</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="exporterInvoiceDate"
                // value={
                //   formatDate(contract.shipmentWiseData.exporterInvoiceDate) ||
                //   ""
                // }
                value={shipmentWiseData.exporterInvoiceDate || ""}
                onChange={handleInputChangeShipmentWise}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Box>
            <FormLabel {...FormLabelStyle} style={{ color: "green" }}>
              Shipped Quantity (In MTS)
            </FormLabel>
            <Input
              type="text"
              placeholder=""
              name="totalShippedQuantity"
              // value={shipmentWiseData.quantityInMTS || formData.fixedQuantity}
              value={
                shipmentWiseData.totalShippedQuantity ||
                calculateTotalShippedQuantity()
              }
              onChange={handleInputChangeShipmentWise}
              {...InputStyle}
              isReadOnly
              focusBorderColor="green"
              borderColor={"green"}
              bgColor={"green.100"}
            />
          </Box>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle} style={{ color: "green" }}>
                Remaining Quantity (In MTS)
              </FormLabel>
              <Input
                type="text"
                placeholder=""
                name="remainingQuantity"
                // value={shipmentWiseData.quantityInMTS || formData.fixedQuantity}
                value={
                  shipmentWiseData.remainingQuantity ||
                  calculateRemainingQuantity()
                }
                onChange={handleInputChangeShipmentWise}
                {...InputStyle}
                isReadOnly
                focusBorderColor="green"
                borderColor={"green"}
                bgColor={"green.100"}
              />
            </Box>
          </Flex>
        </Flex>
        <Box>
          {rows?.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Item Name</FormLabel>
                )}
                <ChakraSelect
                  name="itemName"
                  value={row.itemName || contract.itemName}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                >
                  <option value="" disabled>
                    Item Name
                  </option>
                  {itemNamesList.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </ChakraSelect>
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Quantity</FormLabel>
                )}
                <Input
                  type="text"
                  name="quantity"
                  value={row.quantity}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && <FormLabel {...FormLabelStyle}>HSN</FormLabel>}
                <Input
                  type="text"
                  name="hsn"
                  value={row.hsn}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && <FormLabel {...FormLabelStyle}>Rate</FormLabel>}
                <Input
                  type="text"
                  name="rate"
                  value={row.rate}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Currency</FormLabel>
                )}
                <ChakraSelect
                  name="currency"
                  value={row.currency}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                >
                  <option value="" disabled>
                    Currency
                  </option>
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                  <option value="EUR">Euro</option>
                  <option value="QAR">Qatar Riyal</option>
                  <option value="SAR">Saudi Riyal</option>
                </ChakraSelect>
              </Box>
              {contract.salesContract.typeOfImport === "High Sea Sale Import" && (
                <>
                  <Box>
                    {index === 0 && (
                      <FormLabel {...FormLabelStyle}>Exchange Rate</FormLabel>
                    )}
                    <Input
                      type="text"
                      name="exchangeRate"
                      value={row.exchangeRate}
                      onChange={(e) => handleInputChangeRows(e, index)}
                      focusBorderColor="teal.400"
                    />
                  </Box>
                  <Box>
                    {index === 0 && (
                      <FormLabel {...FormLabelStyle}>HSS Value</FormLabel>
                    )}
                    <Input
                      type="text"
                      name="hssValueUSD"
                      value={row.hssValueUSD}
                      onChange={(e) => handleInputChangeRows(e, index)}
                      focusBorderColor="teal.400"
                    />
                  </Box>
                  <Box>
                    {index === 0 && (
                      <FormLabel {...FormLabelStyle}>
                        HSS Value In INR
                      </FormLabel>
                    )}
                    <Input
                      type="text"
                      name="hssValueINR"
                      value={row.hssValueINR}
                      onChange={(e) => handleInputChangeRows(e, index)}
                      focusBorderColor="teal.400"
                    />
                  </Box>
                </>
              )}
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle} style={{ color: "green" }}>
                    Actual Invoice Value
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="invoiceValue"
                  value={row.invoiceValue}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="green"
                  borderColor={"green"}
                  bgColor={"green.100"}
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Exporter Invoice Value
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="invoiceValueExporter"
                  value={row.invoiceValueExporter}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Difference</FormLabel>
                )}
                <Input
                  type="text"
                  name="difference"
                  value={row.difference}
                  onChange={(e) => handleInputChangeRows(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRow}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRow}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rows.length <= 1}
            />
          </Flex>
        </Box>
      </FormControl>
    </Box>
  );

  const initialDocuments = [
    "Loading Picture",
    "Bill of Lading",
    "Certificate of Origin",
    "Commercial Invoice",
    "Packing List",
    "Test Analysis Report",
    "Self-Declaration",
    "Form-I",
    "Flow Chart",
  ];

  const [documentsData, setDocumentsData] = useState(
    contract.documentsStatusData[0] || {}
  );

  // Define state for the document statuses
  const [draftStatusFromShipper, setDraftStatusFromShipper] = useState(
    documentsData.draftStatusFromShipper || {}
  );

  const [originalStatusFromShipper, setOriginalStatusFromShipper] = useState(
    documentsData.originalStatusFromShipper || {}
  );

  const [draftStatusToClearingAgent, setDraftStatusToClearingAgent] = useState(
    documentsData.draftStatusToClearingAgent || {}
  );

  const [originalStatusToClearingAgent, setOriginalStatusToClearingAgent] =
    useState(documentsData.originalStatusToClearingAgent || {});

  // Initialize checked states for originals
  const [
    originalStatusFromShipperChecked,
    setOriginalStatusFromShipperChecked,
  ] = useState(
    initialDocuments.reduce(
      (acc, doc) => ({
        ...acc,
        [doc]: !!documentsData.originalStatusFromShipper?.[doc],
      }),
      {}
    )
  );

  const [
    originalStatusToClearingAgentChecked,
    setOriginalStatusToClearingAgentChecked,
  ] = useState(
    initialDocuments.reduce(
      (acc, doc) => ({
        ...acc,
        [doc]: !!documentsData.originalStatusToClearingAgent?.[doc],
      }),
      {}
    )
  );

  // Handlers to manage changes in the status
  const handleDraftChangeFromShipper = (doc) => {
    setDraftStatusFromShipper((prevState) => ({
      ...prevState,
      [doc]: !prevState[doc],
    }));
  };

  const handleDraftChangeToClearingAgent = (doc) => {
    setDraftStatusToClearingAgent((prevState) => ({
      ...prevState,
      [doc]: !prevState[doc],
    }));
  };

  const handleOriginalChange = (setOriginalStatus, doc, value) => {
    setOriginalStatus((prevState) => ({
      ...prevState,
      [doc]: value,
    }));
  };

  const handleOriginalCheckedChange = (setOriginalStatusChecked, doc) => {
    setOriginalStatusChecked((prevState) => ({
      ...prevState,
      [doc]: !prevState[doc],
    }));
  };

  const handleSelectAllDraft = (draftStatus, setDraftStatus) => {
    const allSelected = Object.values(draftStatus).every((status) => status);
    const newState = initialDocuments.reduce(
      (acc, doc) => ({ ...acc, [doc]: !allSelected }),
      {}
    );
    setDraftStatus(newState);
  };

  const [rowsShipper, setRowsShipper] = useState(
    contract?.rowsShipper?.length > 0
      ? contract.rowsShipper
      : [
          {
            selectedDocuments: [],
            location: "",
            companyName: "",
            trackingDetails: "",
            receivingStatus: "",
            refNo: "",
          },
        ]
  );
  const handleCheckboxShipperChange = (index, doc) => {
    setRowsShipper((prev) => {
      const updatedRows = [...prev];
      const { selectedDocuments } = updatedRows[index];
      updatedRows[index].selectedDocuments = selectedDocuments.includes(doc)
        ? selectedDocuments.filter((d) => d !== doc)
        : [...selectedDocuments, doc];
      return updatedRows;
    });
  };

  const handleInputShipperChange = (index, field, value) => {
    setRowsShipper((prev) => {
      const updatedRows = [...prev];
      updatedRows[index][field] = value;
      return updatedRows;
    });
  };

  const addShipperRow = () => {
    setRowsShipper([
      ...rowsShipper,
      {
        selectedDocuments: [],
        location: "",
        companyName: "",
        trackingDetails: "",
        receivingStatus: "",
        refNo: "",
      },
    ]);
  };

  const removeShipperRow = () => {
    setRowsShipper(rowsShipper.slice(0, -1));
  };

  const [rowsDeliver, setRowsDeliver] = useState(
    contract?.rowsDeliver?.length > 0
      ? contract.rowsDeliver
      : [
          {
            selectedDocuments: [],
            chaName: "",
            location: "",
            trackingDetails: "",
            deliveryStatus: "",
          },
        ]
  );
  const handleCheckboxDeliverChange = (index, doc) => {
    setRowsDeliver((prev) => {
      const updatedRows = [...prev];
      const { selectedDocuments } = updatedRows[index];
      updatedRows[index].selectedDocuments = selectedDocuments.includes(doc)
        ? selectedDocuments.filter((d) => d !== doc)
        : [...selectedDocuments, doc];
      return updatedRows;
    });
  };

  const handleInputDeliverChange = (index, field, value) => {
    setRowsDeliver((prev) => {
      const updatedRows = [...prev];
      updatedRows[index][field] = value;
      return updatedRows;
    });
  };

  const addDeliverRow = () => {
    setRowsDeliver([
      ...rowsDeliver,
      {
        selectedDocuments: [],
        chaName: "",
        location: "",
        trackingDetails: "",
        deliveryStatus: "",
      },
    ]);
  };

  const removeDeliverRow = () => {
    setRowsDeliver(rowsDeliver.slice(0, -1));
  };

  const renderDocumentStatus = () => (
    <Box p={4} overflowX="auto">
      <Flex justifyContent={"flex-start"}>
        <Heading
          as="h6"
          size="xs"
          textAlign={"start"}
          textDecoration={"underline"}
          mb={5}
        >
          Document Status
        </Heading>
      </Flex>
      <Table variant="simple" size="sm" border={"1px solid gray"}>
        <Thead border={"1px solid gray"}>
          <Tr border={"1px solid gray"}>
            <Th textAlign="center" border={"1px solid gray"}></Th>
            <Th colSpan={2} textAlign="center" border={"1px solid gray"}>
              Documents Receiving Status from Shipper
            </Th>
            <Th colSpan={2} textAlign="center" border={"1px solid gray"}>
              Documents Giving Status to Clearing Agent
            </Th>
          </Tr>
          <Tr border={"1px solid gray"}>
            <Th textAlign="center" border={"1px solid gray"}>
              Documents Name
            </Th>
            <Th textAlign="center" border={"1px solid gray"}>
              Draft Documents Status
            </Th>
            <Th textAlign="center" border={"1px solid gray"}>
              Original Documents Status
            </Th>
            <Th textAlign="center" border={"1px solid gray"}>
              Draft Documents Status
            </Th>
            <Th textAlign="center" border={"1px solid gray"}>
              Original Documents Status
            </Th>
          </Tr>
          <Tr border={"1px solid gray"}>
            <Th textAlign="center" border={"1px solid gray"}></Th>
            <Th border={"1px solid gray"}>
              <Checkbox
                isChecked={Object.values(draftStatusFromShipper).every(
                  (status) => status
                )}
                onChange={() =>
                  handleSelectAllDraft(
                    draftStatusFromShipper,
                    setDraftStatusFromShipper
                  )
                }
              />
              <span style={{ marginLeft: 10 }}>Select All </span>
            </Th>
            <Th border={"1px solid gray"}>
              <Checkbox
                isChecked={Object.values(
                  originalStatusFromShipperChecked
                ).every((status) => status)}
                onChange={() =>
                  handleSelectAllDraft(
                    originalStatusFromShipperChecked,
                    setOriginalStatusFromShipperChecked
                  )
                }
              />
            </Th>
            <Th border={"1px solid gray"}>
              <Checkbox
                isChecked={Object.values(draftStatusToClearingAgent).every(
                  (status) => status
                )}
                onChange={() =>
                  handleSelectAllDraft(
                    draftStatusToClearingAgent,
                    setDraftStatusToClearingAgent
                  )
                }
              />
              <span style={{ marginLeft: 10 }}>Select All </span>
            </Th>
            <Th border={"1px solid gray"}>
              <Checkbox
                isChecked={Object.values(
                  originalStatusToClearingAgentChecked
                ).every((status) => status)}
                onChange={() =>
                  handleSelectAllDraft(
                    originalStatusToClearingAgentChecked,
                    setOriginalStatusToClearingAgentChecked
                  )
                }
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {initialDocuments.map((doc) => (
            <Tr key={doc} border={"1px solid gray"}>
              <Td border={"1px solid gray"}>{doc}</Td>
              <Td border={"1px solid gray"}>
                <Checkbox
                  isChecked={draftStatusFromShipper[doc]}
                  onChange={() => handleDraftChangeFromShipper(doc)}
                />
              </Td>
              <Td border={"1px solid gray"}>
                <Flex justifyContent={"start"} gap={2}>
                  <Checkbox
                    isChecked={originalStatusFromShipperChecked[doc]}
                    onChange={() =>
                      handleOriginalCheckedChange(
                        setOriginalStatusFromShipperChecked,
                        doc
                      )
                    }
                  />
                  <Input
                    type="date"
                    value={originalStatusFromShipper[doc] || ""}
                    onChange={(e) =>
                      handleOriginalChange(
                        setOriginalStatusFromShipper,
                        doc,
                        e.target.value
                      )
                    }
                  />
                </Flex>
              </Td>
              <Td border={"1px solid gray"}>
                <Checkbox
                  isChecked={draftStatusToClearingAgent[doc]}
                  onChange={() => handleDraftChangeToClearingAgent(doc)}
                />
              </Td>
              <Td border={"1px solid gray"}>
                <Flex justifyContent={"start"} gap={2}>
                  <Checkbox
                    isChecked={originalStatusToClearingAgentChecked[doc]}
                    onChange={() =>
                      handleOriginalCheckedChange(
                        setOriginalStatusToClearingAgentChecked,
                        doc
                      )
                    }
                  />
                  <Input
                    type="date"
                    value={originalStatusToClearingAgent[doc] || ""}
                    onChange={(e) =>
                      handleOriginalChange(
                        setOriginalStatusToClearingAgent,
                        doc,
                        e.target.value
                      )
                    }
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box mt={8}>
        <Flex justifyContent={"flex-start"}>
          <Heading
            as="h6"
            size="xs"
            textAlign={"start"}
            textDecoration={"underline"}
            mb={5}
          >
            Original Documents Tracking from Shipper
          </Heading>
        </Flex>

        <Table variant="simple" size="sm" border={"1px solid gray"}>
          <Thead border={"1px solid gray"}>
            <Tr border={"1px solid gray"}>
              <Th border={"1px solid gray"}>From Shipper</Th>
              <Th border={"1px solid gray"}>Courier Delivery Location</Th>
              <Th border={"1px solid gray"}>Courier Company Name</Th>
              <Th border={"1px solid gray"}>Tracking Details</Th>
              <Th border={"1px solid gray"}>Documents Receiving Status</Th>
              <Th border={"1px solid gray"}>
                Lodgement Status in Bank Ref No.
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {rowsShipper.map((row, index) => (
              <Tr key={index} border={"1px solid gray"}>
                <Td border={"1px solid gray"}>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      width={200}
                    >
                      <Box
                        as="span"
                        display="block"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxWidth="100%"
                      >
                        {row.selectedDocuments.length > 0
                          ? row.selectedDocuments.join(", ")
                          : "Complete Set of Documents"}
                      </Box>
                    </MenuButton>
                    <MenuList>
                      {initialDocuments.map((doc) => (
                        <MenuItem key={doc} closeOnSelect={false}>
                          <Checkbox
                            isChecked={row.selectedDocuments.includes(doc)}
                            onChange={() =>
                              handleCheckboxShipperChange(index, doc)
                            }
                            mr={2}
                          >
                            {doc}
                          </Checkbox>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Location"
                    value={row.location}
                    onChange={(e) =>
                      handleInputShipperChange(
                        index,
                        "location",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Company Name"
                    value={row.companyName}
                    onChange={(e) =>
                      handleInputShipperChange(
                        index,
                        "companyName",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Tracking Details"
                    value={row.trackingDetails}
                    onChange={(e) =>
                      handleInputShipperChange(
                        index,
                        "trackingDetails",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    type="date"
                    value={formatDate(row.receivingStatus)}
                    onChange={(e) =>
                      handleInputShipperChange(
                        index,
                        "receivingStatus",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Ref No."
                    value={row.refNo}
                    onChange={(e) =>
                      handleInputShipperChange(index, "refNo", e.target.value)
                    }
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Flex mt={4} justifyContent="flex-end" gap={2} wrap="wrap">
          <Button
            onClick={addShipperRow}
            color="white"
            backgroundColor="teal.400"
            borderRadius="15"
            _hover={{ backgroundColor: "green.200", color: "black" }}
          >
            + Add Shipper
          </Button>
          <IconButton
            onClick={removeShipperRow}
            color="white"
            backgroundColor="red"
            borderRadius="15"
            _hover={{ backgroundColor: "red.400", color: "black" }}
            icon={<MdDeleteForever />}
            aria-label="Remove"
            isDisabled={rowsShipper.length <= 1}
          />
        </Flex>
      </Box>

      <Box mt={8}>
        <Flex justifyContent={"flex-start"}>
          <Heading
            as="h6"
            size="xs"
            textAlign={"start"}
            textDecoration={"underline"}
            mb={5}
          >
            Original Documents Tracking to Clearing Agent House
          </Heading>
        </Flex>

        <Table variant="simple" size="sm" border={"1px solid gray"}>
          <Thead border={"1px solid gray"}>
            <Tr border={"1px solid gray"}>
              <Th border={"1px solid gray"}>From Shipper</Th>
              <Th border={"1px solid gray"}>CHA Name</Th>
              <Th border={"1px solid gray"}>Location</Th>
              <Th border={"1px solid gray"}>Tracking Details</Th>
              <Th border={"1px solid gray"}>Documents Delivery Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {rowsDeliver.map((row, index) => (
              <Tr key={index} border={"1px solid gray"}>
                <Td border={"1px solid gray"}>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      width={200}
                    >
                      <Box
                        as="span"
                        display="block"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxWidth="100%"
                      >
                        {row.selectedDocuments.length > 0
                          ? row.selectedDocuments.join(", ")
                          : "Complete Set of Documents"}
                      </Box>
                    </MenuButton>
                    <MenuList>
                      {initialDocuments.map((doc) => (
                        <MenuItem key={doc} closeOnSelect={false}>
                          <Checkbox
                            isChecked={row.selectedDocuments.includes(doc)}
                            onChange={() =>
                              handleCheckboxDeliverChange(index, doc)
                            }
                            mr={2}
                          >
                            {doc}
                          </Checkbox>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="CHA Name"
                    value={row.chaName}
                    onChange={(e) =>
                      handleInputDeliverChange(index, "chaName", e.target.value)
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Location"
                    value={row.location}
                    onChange={(e) =>
                      handleInputDeliverChange(
                        index,
                        "location",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    placeholder="Tracking Details"
                    value={row.trackingDetails}
                    onChange={(e) =>
                      handleInputDeliverChange(
                        index,
                        "trackingDetails",
                        e.target.value
                      )
                    }
                  />
                </Td>
                <Td border={"1px solid gray"}>
                  <Input
                    type="date"
                    value={formatDate(row.deliveryStatus)}
                    onChange={(e) =>
                      handleInputDeliverChange(
                        index,
                        "deliveryStatus",
                        e.target.value
                      )
                    }
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Flex mt={4} justifyContent="flex-end" gap={2} wrap="wrap">
          <Button
            onClick={addDeliverRow}
            color="white"
            backgroundColor="teal.400"
            borderRadius="15"
            _hover={{ backgroundColor: "green.200", color: "black" }}
          >
            + Add Deliver
          </Button>

          <IconButton
            onClick={removeDeliverRow}
            color="white"
            backgroundColor="red"
            borderRadius="15"
            _hover={{ backgroundColor: "red.400", color: "black" }}
            icon={<MdDeleteForever />}
            aria-label="Remove"
            isDisabled={rowsDeliver.length <= 1}
          />
        </Flex>
      </Box>
    </Box>
  );

  const renderPaymentStatus = () => (
    <Box>
      <FormControl>
        <Box>
          {paymentStatusRows.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              flexDirection={"column"}
              key={index}
            >
              <Heading
                as="h6"
                size="xs"
                textAlign={"start"}
                textDecoration={"underline"}
              >
                Payment {index + 1}
              </Heading>
              <Flex justifyContent={"space-around"} mt={5}>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Payment Term</FormLabel>
                    <ChakraSelect
                      name="modeOfPayment"
                      value={row.modeOfPayment || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    >
                      <option value="" disabled>
                        Payment Term
                      </option>
                      <option value="Cash">Cash</option>
                      <option value="TT">TT</option>
                      <option value="Buyer Credit">Buyer Credit</option>
                      <option value="LC">LC</option>
                      <option value="RTGS">RTGS</option>
                      <option value="Import Collection Bill">
                        Import Collection Bill
                      </option>
                    </ChakraSelect>
                  </Box>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Date</FormLabel>
                    <Input
                      type="date"
                      placeholder=""
                      name="paymentDate"
                      value={formatDate(row.paymentDate) || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                </Flex>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Bank Name</FormLabel>
                    <ChakraSelect
                      name="paymentBankName"
                      value={row.paymentBankName || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    >
                      <option value="" disabled>
                        Bank Name
                      </option>
                      {bankNamesList.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </ChakraSelect>
                  </Box>

                  <Box>
                    <FormLabel {...FormLabelStyle}>
                      Transaction Reference No
                    </FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="transactionRefNo"
                      value={row.transactionRefNo || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                </Flex>
              </Flex>
              <Flex justifyContent={"space-around"} mt={2}>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Amount</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="paymentAmount"
                      value={row.paymentAmount || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Currency</FormLabel>
                    <ChakraSelect
                      name="paymentCurrency"
                      value={row.paymentCurrency || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                      focusBorderColor="teal.400"
                    >
                      <option value="" disabled>
                        Currency
                      </option>
                      <option value="INR">INR</option>
                      <option value="USD">USD</option>
                      <option value="EUR">Euro</option>
                      <option value="QAR">Qatar Riyal</option>
                      <option value="SAR">Saudi Riyal</option>
                    </ChakraSelect>
                  </Box>
                </Flex>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Exchange Rate</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="exchangeRate"
                      value={row.exchangeRate || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                  <Box>
                    <FormLabel {...FormLabelStyle} style={{ color: "green" }}>Amount In INR</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="amountInINR"
                      value={row.amountInINR || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                      focusBorderColor="green"
                      borderColor={"green"}
                      bgColor={"green.100"}
                    />
                  </Box>
                </Flex>
              </Flex>
              <Flex justifyContent={"space-around"} mt={2}>
                <Flex justifyContent={"space-around"} gap={5}>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Bank Charges</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="bankCharges"
                      value={row.bankCharges || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                  <Box>
                    <FormLabel {...FormLabelStyle}>Credit Period</FormLabel>
                    <Input
                      type="text"
                      placeholder=""
                      name="creditPeriod"
                      value={row.creditPeriod || ""}
                      onChange={(e) =>
                        handleInputChangePaymentStatusRows(e, index)
                      }
                      {...SubInputStyle}
                    />
                  </Box>
                </Flex>
                <Box>
                  <FormLabel {...FormLabelStyle} style={{ color: "green" }}>Due Date</FormLabel>
                  <Input
                    type="date"
                    placeholder=""
                    name="paymentDueDate"
                    value={formatDate(row.paymentDueDate) || ""}
                    onChange={(e) =>
                      handleInputChangePaymentStatusRows(e, index)
                    }
                    {...InputStyle}
                    focusBorderColor="green"
                borderColor={"green"}
                bgColor={"green.100"}
                  />
                </Box>
              </Flex>
            </Flex>
          ))}
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewPaymentStatusRow}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewPaymentStatusRow}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={paymentStatusRows.length <= 1}
            />
          </Flex>
        </Box>
        <TableContainer>
          <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
            <Thead>
              <Tr boxShadow={"sm"}>
                <Th
                  boxShadow={"sm"}
                  style={{ textAlign: "start", color: "teal" }}
                >
                  Particulars
                </Th>
                <Th
                  boxShadow={"sm"}
                  style={{ textAlign: "center", color: "teal" }}
                >
                  Amount IN USD
                </Th>
                <Th
                  boxShadow={"sm"}
                  style={{ textAlign: "center", color: "teal" }}
                >
                  Amount IN INR
                </Th>
                <Th
                  boxShadow={"sm"}
                  style={{ textAlign: "center", color: "teal" }}
                >
                  Remark
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  {contract?.salesContract?.typeOfImport === "High Sea Sale Import"
                    ? "HSS Invoice Value"
                    : "Shipper Invoice Value"}
                </Td>

                {contract?.salesContract?.typeOfImport === "High Sea Sale Import"
                    ? <>
                    <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceCurrency"
                    value={
                      paymentStatusData.shipperInvoiceCurrency ||
                      calculateTotalInvoiceValue()
                    }
                   

                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                    focusBorderColor="green"
                    borderColor={"green"}
                    bgColor={"green.100"}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceAmount"
                    value={
                      paymentStatusData.shipperInvoiceAmount ||
                      calculateTotalInvoiceValueINR()
                    }
                  

                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                    readOnly
                    focusBorderColor="green"
                    borderColor={"green"}
                    bgColor={"green.100"}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceRemarks"
                    value={paymentStatusData.shipperInvoiceRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                    </>
                    : <>
                    <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceCurrency"
                    value={
                      paymentStatusData.shipperInvoiceCurrency ||
                      calculateTotalInvoiceValueINR()
                    }
                   

                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                    focusBorderColor="green"
                    borderColor={"green"}
                    bgColor={"green.100"}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceAmount"
                    value={
                      paymentStatusData.shipperInvoiceAmount ||
                      calculateTotalInvoiceValueINR()
                    }
                  

                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                    readOnly
                    focusBorderColor="green"
                    borderColor={"green"}
                    bgColor={"green.100"}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceRemarks"
                    value={paymentStatusData.shipperInvoiceRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td></>}

                {/* <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceCurrency"
                    //value={paymentStatusData.shipperInvoiceCurrency || ""}
                    value={
                      paymentStatusData.shipperInvoiceCurrency ||
                      calculateTotalInvoiceValue()
                    }
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                    focusBorderColor="green"
                borderColor={"green"}
                bgColor={"green.100"}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceAmount"
                    //value={paymentStatusData.shipperInvoiceAmount || ""}
                    value={
                      paymentStatusData.shipperInvoiceAmount ||
                      calculateTotalInvoiceValueINR()
                    }
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                    focusBorderColor="green"
                borderColor={"green"}
                bgColor={"green.100"}
                  />
                </Td> 
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="shipperInvoiceRemarks"
                    value={paymentStatusData.shipperInvoiceRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td> */}
              </Tr>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  Add: Other Amount
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="otherAmountCurrency"
                    value={paymentStatusData.otherAmountCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="otherAmountAmount"
                    value={paymentStatusData.otherAmountAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="otherAmountRemarks"
                    value={paymentStatusData.otherAmountRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  Less: Amount Paid
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessAmountCurrency"
                    value={paymentStatusData.lessAmountCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                    focusBorderColor="green"
                borderColor={"green"}
                bgColor={"green.100"}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessAmountAmount"
                    value={paymentStatusData.lessAmountAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                    focusBorderColor="green"
                borderColor={"green"}
                bgColor={"green.100"}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessAmountRemarks"
                    value={paymentStatusData.lessAmountRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  Less: Credit Note
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessCreditCurrency"
                    value={paymentStatusData.lessCreditCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessCreditAmount"
                    value={paymentStatusData.lessCreditAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessCreditRemarks"
                    value={paymentStatusData.lessCreditRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  Less: Claims
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessClaimCurrency"
                    value={paymentStatusData.lessClaimCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessClaimAmount"
                    value={paymentStatusData.lessClaimAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="lessClaimRemarks"
                    value={paymentStatusData.lessClaimRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                  />
                </Td>
              </Tr>
              <Tr boxShadow={"sm"}>
                <Td
                  style={{ textAlign: "start", fontWeight: "bold" }}
                  {...FormLabelStyle}
                >
                  Balance
                </Td>

                <Td style={{ textAlign: "center" }} isNumeric>
                  <Input
                    type="text"
                    placeholder=""
                    name="balanceCurrency"
                    value={paymentStatusData.balanceCurrency || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                    focusBorderColor="green"
                    borderColor={"green"}
                    bgColor={"green.100"}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="balanceAmount"
                    value={paymentStatusData.balanceAmount || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                    focusBorderColor="green"
                    borderColor={"green"}
                    bgColor={"green.100"}
                  />
                </Td>
                <Td style={{ textAlign: "center" }}>
                  <Input
                    type="text"
                    placeholder=""
                    name="balanceRemarks"
                    value={paymentStatusData.balanceRemarks || ""}
                    onChange={handleInputChangePaymentStatus}
                    {...SubInputStyle}
                    borderColor={"green"}
                    focusBorderColor="green"
                    bgColor={"green.100"}
                  />
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <Box>
            {paginationData.length === 0 && (
              <Alert
                status="info"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mt={4}
              >
                <AlertIcon boxSize={8} />
                No Record Found
              </Alert>
            )}
          </Box>
        </TableContainer>
      </FormControl>
    </Box>
  );

  const [rowsCCS, setRowsCCS] = useState(
    contract?.rowsCCS?.length > 0
      ? contract.rowsCCS
      : [
          {
            dispatchFrom: "",
            dispatchTo: "",
            transporterName: "",
            transporterQty: "",
            transporterRate: "",
          },
        ]
  );

  const handleInputChangeRowsCCS = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rowsCCS];
    newRows[index] = { ...newRows[index], [name]: value };
    setRowsCCS(newRows);
  };

  const addNewRowCCS = () => {
    setRowsCCS([
      ...rowsCCS,
      {
        dispatchFrom: "",
        dispatchTo: "",
        transporterName: "",
        transporterQty: "",
        transporterRate: "",
      },
    ]);
  };

  const removeNewRowCCS = () => {
    if (rowsCCS.length > 1) {
      setRowsCCS(rowsCCS.slice(0, -1));
    }
  };

  const renderCustomClearingStatus = () => (
    <Box>
      <FormControl>
        <Flex justifyContent={"space-around"} mt={5}>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box {...SubInputStyle}>
              <Heading as="h6" size="xs" mt={10} textAlign={"start"}>
                Check List
              </Heading>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Received</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="checkListReceivedDate"
                value={
                  formatDate(
                    customerClearingStatusData.checkListReceivedDate
                  ) || ""
                }
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Approved</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="checkListApprovedDate"
                value={
                  formatDate(
                    customerClearingStatusData.checkListApprovedDate
                  ) || ""
                }
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Approved By</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="checkListApprovedBy"
                value={customerClearingStatusData.checkListApprovedBy || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box {...SubInputStyle}>
              <Heading as="h6" size="xs" mt={10} textAlign={"start"}>
                Bill Of Entry
              </Heading>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>BE No</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="beNo"
                value={customerClearingStatusData.beNo || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>BE Date</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="beDate"
                value={formatDate(customerClearingStatusData.beDate) || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>BE Payment</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="bePaymentDate"
                value={
                  formatDate(customerClearingStatusData.bePaymentDate) || ""
                }
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box {...SubInputStyle}>
              <Heading as="h6" size="xs" mt={10} textAlign={"start"}>
                Seal Photo
              </Heading>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Received</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="sealPhotoDate"
                value={
                  formatDate(customerClearingStatusData.sealPhotoDate) || ""
                }
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Remark</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="sealPhotoRemarks"
                value={customerClearingStatusData.sealPhotoRemarks || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...InputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex justifyContent={"space-around"} mt={2}>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box {...SubInputStyle}>
              <Heading as="h6" size="xs" mt={10} textAlign={"start"}>
                CFS Weight
              </Heading>
            </Box>
            <Box>
              <FormLabel {...FormLabelStyle}>Received</FormLabel>
              <Input
                type="date"
                placeholder=""
                name="cfsWeightDate"
                value={
                  formatDate(customerClearingStatusData.cfsWeightDate) || ""
                }
                onChange={handleInputChangeCustomClearingStatus}
                {...SubInputStyle}
              />
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} gap={5}>
            <Box>
              <FormLabel {...FormLabelStyle}>Remark</FormLabel>
              <Input
                type="text"
                placeholder=""
                name="cfsWeightRemarks"
                value={customerClearingStatusData.cfsWeightRemarks || ""}
                onChange={handleInputChangeCustomClearingStatus}
                {...InputStyle}
              />
            </Box>
          </Flex>
        </Flex>
        <Box mt={25}>
          {rowsCCS?.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Dispatch From</FormLabel>
                )}
                <Input
                  type="text"
                  name="dispatchFrom"
                  value={row.dispatchFrom}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Dispatch To</FormLabel>
                )}
                <Input
                  type="text"
                  name="dispatchTo"
                  value={row.dispatchTo}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Transporter Name</FormLabel>
                )}
                <ChakraSelect
                  name="transporterName"
                  value={row.transporterName}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                  {...MiniMultiInputStyle}
                >
                  <option value="" disabled>
                    Transporter Name
                  </option>
                  {transporterNamesList.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </ChakraSelect>
              </Box>

              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Tranporter Quantity</FormLabel>
                )}
                <Input
                  type="text"
                  name="transporterQty"
                  value={row.transporterQty}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Transporter Rate</FormLabel>
                )}
                <Input
                  type="text"
                  name="transporterRate"
                  value={row.transporterRate}
                  onChange={(e) => handleInputChangeRowsCCS(e, index)}
                  focusBorderColor="teal.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRowCCS}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRowCCS}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rowsCCS.length <= 1}
            />
          </Flex>
        </Box>
      </FormControl>
    </Box>
  );

  const [rowsWeightLevel1, setRowsWeightLevel1] = useState(
    contract?.rowsWeightLevel1?.length > 0
      ? contract.rowsWeightLevel1
      : [
          {
            containerNumber: "",
            noOfPackage: "",
            invoiceNetWeight: "",
            emptyContainerWeight: "",
            cfsWeight1: "",
            cfsWeight2: "",
            averageWeight3: "",
          },
        ]
  );

  const [totalsWeightLevel1, setTotalsWeightLevel1] = useState({
    noOfPackage: 0,
    invoiceNetWeight: 0,
    emptyContainerWeight: 0,
    cfsWeight1: 0,
    cfsWeight2: 0,
    averageWeight3: 0,
  });

  // Function to calculate totals based on current row data
  const calculateTotals = (rows) => {
    const newTotals = rows.reduce(
      (acc, row) => {
        acc.noOfPackage += parseFloat(row.noOfPackage) || 0;
        acc.invoiceNetWeight += parseFloat(row.invoiceNetWeight) || 0;
        acc.emptyContainerWeight += parseFloat(row.emptyContainerWeight) || 0;
        acc.cfsWeight1 += parseFloat(row.cfsWeight1) || 0;
        acc.cfsWeight2 += parseFloat(row.cfsWeight2) || 0;
        acc.averageWeight3 += parseFloat(row.averageWeight3) || 0;
        return acc;
      },
      {
        noOfPackage: 0,
        invoiceNetWeight: 0,
        emptyContainerWeight: 0,
        cfsWeight1: 0,
        cfsWeight2: 0,
        averageWeight3: 0,
      }
    );
    setTotalsWeightLevel1(newTotals);
  };

  // Initialize the totals when the component mounts
  useEffect(() => {
    if (contract?.rowsWeightLevel1?.length > 0) {
      calculateTotals(contract.rowsWeightLevel1);
    }
    if (contract?.rowsWeightLevel2?.length > 0) {
      calculateTotalsWeightLevel2(contract.rowsWeightLevel2);
    }
    if (contract?.rowsWeightLevel3?.length > 0) {
      calculateTotalsWeightLevel3(contract.rowsWeightLevel3);
    }
  }, [contract]);

  // Handle input changes and update both rows and totals
  const handleInputChangeRowsWeightLevel1 = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rowsWeightLevel1];
    newRows[index] = { ...newRows[index], [name]: value };

    // Automatically calculate averageWeight3
    const cfsWeight1 = parseFloat(newRows[index].cfsWeight1) || 0;
    const cfsWeight2 = parseFloat(newRows[index].cfsWeight2) || 0;

    if (cfsWeight1 === 0 || cfsWeight2 === 0) {
      // If any of the weights is zero, set averageWeight3 to the non-zero value
      newRows[index].averageWeight3 = cfsWeight1 || cfsWeight2;
    } else {
      // Otherwise, calculate the average
      newRows[index].averageWeight3 = ((cfsWeight1 + cfsWeight2) / 2).toFixed(
        2
      );
    }

    setRowsWeightLevel1(newRows);
    calculateTotals(newRows);
  };

  const addNewRowWeightLevel1 = () => {
    setRowsWeightLevel1([
      ...rowsWeightLevel1,
      {
        containerNumber: "",
        noOfPackage: "",
        invoiceNetWeight: "",
        emptyContainerWeight: "",
        cfsWeight1: "",
        cfsWeight2: "",
        averageWeight3: "",
      },
    ]);
  };

  const removeNewRowWeightLevel1 = () => {
    if (rowsWeightLevel1.length > 1) {
      setRowsWeightLevel1(rowsWeightLevel1.slice(0, -1));
    }
  };

  const [rowsWeightLevel2, setRowsWeightLevel2] = useState(
    contract?.rowsWeightLevel2?.length > 0
      ? contract.rowsWeightLevel2
      : [
          {
            vehicleNumber: "",
            noOfPackageLoad: "",
            portLoadingWeight1: "",
            portLoadingWeight2: "",
            averageWeight3: "",
            dispatchDate: "",
            ewayBillNo: "",
          },
        ]
  );

  const [totalsWeightLevel2, setTotalsWeightLevel2] = useState({
    noOfPackageLoad: 0,
    portLoadingWeight1: 0,
    portLoadingWeight2: 0,
    averageWeight3: 0,
  });

  const handleInputChangeRowsWeightLevel2 = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rowsWeightLevel2];
    newRows[index] = { ...newRows[index], [name]: value };

    // Automatically calculate averageWeight3
    const portLoadingWeight1 =
      parseFloat(newRows[index].portLoadingWeight1) || 0;
    const portLoadingWeight2 =
      parseFloat(newRows[index].portLoadingWeight2) || 0;

    if (portLoadingWeight1 === 0 || portLoadingWeight2 === 0) {
      // If any of the weights is zero, set averageWeight3 to the non-zero value
      newRows[index].averageWeight3 = portLoadingWeight1 || portLoadingWeight2;
    } else {
      // Otherwise, calculate the average and fix it to 2 decimal places
      newRows[index].averageWeight3 = (
        (portLoadingWeight1 + portLoadingWeight2) /
        2
      ).toFixed(2);
    }

    setRowsWeightLevel2(newRows);
    calculateTotalsWeightLevel2(newRows);
  };

  const calculateTotalsWeightLevel2 = (rows2) => {
    const newTotals = rows2.reduce(
      (acc, row) => {
        acc.noOfPackageLoad += parseFloat(row.noOfPackageLoad) || 0;
        acc.portLoadingWeight1 += parseFloat(row.portLoadingWeight1) || 0;
        acc.portLoadingWeight2 += parseFloat(row.portLoadingWeight2) || 0;
        acc.averageWeight3 += parseFloat(row.averageWeight3) || 0;
        return acc;
      },
      {
        noOfPackageLoad: 0,
        portLoadingWeight1: 0,
        portLoadingWeight2: 0,
        averageWeight3: 0,
      }
    );
    setTotalsWeightLevel2(newTotals);
  };

  const addNewRowWeightLevel2 = () => {
    setRowsWeightLevel2([
      ...rowsWeightLevel2,
      {
        vehicleNumber: "",
        noOfPackageLoad: "",
        portLoadingWeight1: "",
        portLoadingWeight2: "",
        averageWeight3: "",
        dispatchDate: "",
        ewayBillNo: "",
      },
    ]);
  };

  const removeNewRowWeightLevel2 = () => {
    if (rowsWeightLevel2.length > 1) {
      setRowsWeightLevel2(rowsWeightLevel2.slice(0, -1));
    }
  };

  const [rowsWeightLevel3, setRowsWeightLevel3] = useState(
    contract?.rowsWeightLevel3?.length > 0
      ? contract.rowsWeightLevel3
      : [
          {
            vehicleNumber: "",
            noOfPackageLoad: "",
            destinationWeight1: "",
            destinationWeight2: "",
            destinationWeight3: "",
            averageWeight4: "",
            materialReceivedDate: "",
          },
        ]
  );

  const [totalsWeightLevel3, setTotalsWeightLevel3] = useState({
    noOfPackageLoad: 0,
    destinationWeight1: 0,
    destinationWeight2: 0,
    destinationWeight3: 0,
    averageWeight4: 0,
  });

  const handleInputChangeRowsWeightLevel3 = (e, index) => {
    const { name, value } = e.target;
    const newRows = [...rowsWeightLevel3];
    newRows[index] = { ...newRows[index], [name]: value };

    // Automatically calculate averageWeight4
    const destinationWeight1 =
      parseFloat(newRows[index].destinationWeight1) || 0;
    const destinationWeight2 =
      parseFloat(newRows[index].destinationWeight2) || 0;
    const destinationWeight3 =
      parseFloat(newRows[index].destinationWeight3) || 0;

    // Count how many weights are non-zero
    const nonZeroWeights = [
      destinationWeight1,
      destinationWeight2,
      destinationWeight3,
    ].filter((weight) => weight > 0);
    const nonZeroCount = nonZeroWeights.length;

    if (nonZeroCount === 0) {
      // If all weights are zero, set averageWeight4 to 0
      newRows[index].averageWeight4 = 0;
    } else if (nonZeroCount === 1) {
      // If only one weight is non-zero, set averageWeight4 to that weight
      newRows[index].averageWeight4 = nonZeroWeights[0];
    } else {
      // Otherwise, calculate the average of the non-zero weights
      newRows[index].averageWeight4 = (
        nonZeroWeights.reduce((sum, weight) => sum + weight, 0) / nonZeroCount
      ).toFixed(2);
    }

    setRowsWeightLevel3(newRows);
    calculateTotalsWeightLevel3(newRows);
  };

  const calculateTotalsWeightLevel3 = (rows3) => {
    const newTotals = rows3.reduce(
      (acc, row) => {
        acc.noOfPackageLoad += parseFloat(row.noOfPackageLoad) || 0;
        acc.destinationWeight1 += parseFloat(row.destinationWeight1) || 0;
        acc.destinationWeight2 += parseFloat(row.destinationWeight2) || 0;
        acc.destinationWeight3 += parseFloat(row.destinationWeight3) || 0;
        acc.averageWeight4 += parseFloat(row.averageWeight4) || 0;
        return acc;
      },
      {
        noOfPackageLoad: 0,
        destinationWeight1: 0,
        destinationWeight2: 0,
        destinationWeight3: 0,
        averageWeight4: 0,
      }
    );
    setTotalsWeightLevel3(newTotals);
  };

  const addNewRowWeightLevel3 = () => {
    setRowsWeightLevel3([
      ...rowsWeightLevel3,
      {
        vehicleNumber: "",
        noOfPackageLoad: "",
        destinationWeight1: "",
        destinationWeight2: "",
        destinationWeight3: "",
        averageWeight4: "",
        materialReceivedDate: "",
      },
    ]);
  };

  const removeNewRowWeightLevel3 = () => {
    if (rowsWeightLevel3.length > 1) {
      setRowsWeightLevel3(rowsWeightLevel3.slice(0, -1));
    }
  };

  // Initialize state based on contract data if available
  const [weightSummaryData, setWeightSummaryData] = useState({
    invoiceWD: contract?.weightSummaryData?.invoiceWD || 0,
    invoicePD: contract?.weightSummaryData?.invoicePD || 0,
    invoiceSW: contract?.weightSummaryData?.invoiceSW || 0,
    invoiceSP: contract?.weightSummaryData?.invoiceSP || 0,
    cfsWD: contract?.weightSummaryData?.cfsWD || 0,
    cfsPD: contract?.weightSummaryData?.cfsPD || 0,
    cfsSW: contract?.weightSummaryData?.cfsSW || 0,
    cfsSP: contract?.weightSummaryData?.cfsSP || 0,
    loadingWD: contract?.weightSummaryData?.loadingWD || 0,
    loadingPD: contract?.weightSummaryData?.loadingPD || 0,
    loadingSW: contract?.weightSummaryData?.loadingSW || 0,
    loadingSP: contract?.weightSummaryData?.loadingSP || 0,
    destinationWD: contract?.weightSummaryData?.destinationWD || 0,
    destinationPD: contract?.weightSummaryData?.destinationPD || 0,
    destinationSW: contract?.weightSummaryData?.destinationSW || 0,
    destinationSP: contract?.weightSummaryData?.destinationSP || 0,
  });

  // Update weightSummaryData whenever totals change
  useEffect(() => {
    setWeightSummaryData((prevData) => ({
      invoiceWD:
        parseFloat(totalsWeightLevel1.invoiceNetWeight.toFixed(2)) ||
        prevData.invoiceWD,
      invoicePD:
        parseFloat(totalsWeightLevel1.noOfPackage.toFixed(2)) ||
        prevData.invoicePD,
      invoiceSW: prevData.invoiceSW, // Keeping this constant unless there's logic for this
      invoiceSP: prevData.invoiceSP, // Keeping this constant unless there's logic for this
      cfsWD:
        parseFloat(totalsWeightLevel1.averageWeight3.toFixed(2)) ||
        prevData.cfsWD,
      cfsPD:
        parseFloat(totalsWeightLevel1.noOfPackage.toFixed(2)) || prevData.cfsPD,
      cfsSW:
        parseFloat(
          (
            totalsWeightLevel1.invoiceNetWeight -
            totalsWeightLevel1.averageWeight3
          ).toFixed(2)
        ) || prevData.cfsSW,
      cfsSP:
        parseFloat(
          (
            totalsWeightLevel1.noOfPackage - totalsWeightLevel1.noOfPackage
          ).toFixed(2)
        ) || prevData.cfsSP, // Will always be 0
      loadingWD:
        parseFloat(totalsWeightLevel2.averageWeight3.toFixed(2)) ||
        prevData.loadingWD,
      loadingPD:
        parseFloat(totalsWeightLevel2.noOfPackageLoad.toFixed(2)) ||
        prevData.loadingPD,
      loadingSW:
        parseFloat(
          (
            totalsWeightLevel1.invoiceNetWeight -
            totalsWeightLevel2.averageWeight3
          ).toFixed(2)
        ) || prevData.loadingSW,
      loadingSP:
        parseFloat(
          (
            totalsWeightLevel1.noOfPackage - totalsWeightLevel2.noOfPackageLoad
          ).toFixed(2)
        ) || prevData.loadingSP,
      destinationWD:
        parseFloat(totalsWeightLevel3.averageWeight4.toFixed(2)) ||
        prevData.destinationWD,
      destinationPD:
        parseFloat(totalsWeightLevel3.noOfPackageLoad.toFixed(2)) ||
        prevData.destinationPD,
      destinationSW:
        parseFloat(
          (
            totalsWeightLevel1.invoiceNetWeight -
            totalsWeightLevel3.averageWeight4
          ).toFixed(2)
        ) || prevData.destinationSW,
      destinationSP:
        parseFloat(
          (
            totalsWeightLevel1.noOfPackage - totalsWeightLevel3.noOfPackageLoad
          ).toFixed(2)
        ) || prevData.destinationSP,
    }));
  }, [totalsWeightLevel1, totalsWeightLevel2, totalsWeightLevel3, contract]);

  const renderWeightSummary = () => (
    <Box>
      <FormControl>
        <Heading
          as="h6"
          size="xs"
          textAlign={"start"}
          textDecoration={"underline"}
        >
          Level-I Weight Summary at CFS [Inside Port]
        </Heading>
        <Box>
          {rowsWeightLevel1?.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Container Number</FormLabel>
                )}
                <Input
                  type="text"
                  name="containerNumber"
                  value={row.containerNumber}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Number Of Package</FormLabel>
                )}
                <Input
                  type="text"
                  name="noOfPackage"
                  value={row.noOfPackage}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Invoice Net Weight</FormLabel>
                )}
                <Input
                  type="text"
                  name="invoiceNetWeight"
                  value={row.invoiceNetWeight}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Empty Container Weight
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="emptyContainerWeight"
                  value={row.emptyContainerWeight}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>CFS Net Weight-I</FormLabel>
                )}
                <Input
                  type="text"
                  name="cfsWeight1"
                  value={row.cfsWeight1}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>CFS Net Weight-II</FormLabel>
                )}
                <Input
                  type="text"
                  name="cfsWeight2"
                  value={row.cfsWeight2}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle} style={{ color: "green" }}>Average Weight-III</FormLabel>
                )}
                <Input
                  type="text"
                  name="averageWeight3"
                  value={row.averageWeight3}
                  onChange={(e) => handleInputChangeRowsWeightLevel1(e, index)}
                  focusBorderColor="green"
                  borderColor={"green"}
                  bgColor={"green.100"}
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"space-around"} mt={5} pl={35} pr={35}>
            <Input
              type="text"
              name="containerNumber"
              value="Total"
              isReadOnly
              focusBorderColor="teal.400"
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="noOfPackage"
              value={totalsWeightLevel1.noOfPackage}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="invoiceNetWeight"
              value={totalsWeightLevel1.invoiceNetWeight}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="emptyContainerWeight"
              value={totalsWeightLevel1.emptyContainerWeight}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="cfsWeight1"
              value={totalsWeightLevel1.cfsWeight1}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="cfsWeight2"
              value={totalsWeightLevel1.cfsWeight2}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="averageWeight3"
              value={totalsWeightLevel1.averageWeight3}
            
              isReadOnly
              focusBorderColor="green"
                borderColor={"green"}
                bgColor={"green.100"}
            />
          </Flex>
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRowWeightLevel1}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRowWeightLevel1}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rowsWeightLevel1.length <= 1}
            />
          </Flex>
        </Box>

        <Heading
          as="h6"
          size="xs"
          textAlign={"start"}
          textDecoration={"underline"}
        >
          Level-II Weight Summary Of Loading at Destination CFS [Outside Port]
        </Heading>
        <Box>
          {rowsWeightLevel2?.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Vehicle No</FormLabel>
                )}
                <Input
                  type="text"
                  name="vehicleNumber"
                  value={row.vehicleNumber}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>No of Package Load</FormLabel>
                )}
                <Input
                  type="text"
                  name="noOfPackageLoad"
                  value={row.noOfPackageLoad}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Port Loading Weight-I
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="portLoadingWeight1"
                  value={row.portLoadingWeight1}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Port Loading Weight-II
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="portLoadingWeight2"
                  value={row.portLoadingWeight2}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle} style={{ color: "green" }}>Average Weight-III</FormLabel>
                )}
                <Input
                  type="text"
                  name="averageWeight3"
                  value={row.averageWeight3}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="green"
                  borderColor={"green"}
                  bgColor={"green.100"}
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Dispatch Date</FormLabel>
                )}
                <Input
                  type="date"
                  name="dispatchDate"
                  value={formatDate(row.dispatchDate)}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Ewaybill No</FormLabel>
                )}
                <Input
                  type="text"
                  name="ewayBillNo"
                  value={row.ewayBillNo}
                  onChange={(e) => handleInputChangeRowsWeightLevel2(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"space-around"} mt={5} pl={35} pr={35}>
            <Input
              type="text"
              name="vehicleNumber"
              value="Total"
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="noOfPackageLoad"
              value={totalsWeightLevel2.noOfPackageLoad}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="portLoadingWeight1"
              value={totalsWeightLevel2.portLoadingWeight1}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="portLoadingWeight2"
              value={totalsWeightLevel2.portLoadingWeight2}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="averageWeight3"
              value={totalsWeightLevel2.averageWeight3}
             
              isReadOnly
              focusBorderColor="green"
              borderColor={"green"}
              bgColor={"green.100"}
            />
            <Input
              type="text"
              name="dispatchDate"
              value=""
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
              visibility={"hidden"}
            />
            <Input
              type="text"
              name="ewayBillNo"
              value=""
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
              visibility={"hidden"}
            />
          </Flex>

          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRowWeightLevel2}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRowWeightLevel2}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rowsWeightLevel2.length <= 1}
            />
          </Flex>
        </Box>

        <Heading
          as="h6"
          size="xs"
          textAlign={"start"}
          textDecoration={"underline"}
        >
          Level-III Weight Summary at Factory [Outside Port]
        </Heading>
        <Box>
          {rowsWeightLevel3?.map((row, index) => (
            <Flex
              justifyContent={"space-around"}
              mt={5}
              pl={35}
              pr={35}
              key={index}
            >
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>Vehicle No</FormLabel>
                )}
                <Input
                  type="text"
                  name="vehicleNumber"
                  value={row.vehicleNumber}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>No of Package Load</FormLabel>
                )}
                <Input
                  type="text"
                  name="noOfPackageLoad"
                  value={row.noOfPackageLoad}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Destination Weight-I
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="destinationWeight1"
                  value={row.destinationWeight1}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Destination Weight-II
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="destinationWeight2"
                  value={row.destinationWeight2}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Destination Weight-III
                  </FormLabel>
                )}
                <Input
                  type="text"
                  name="destinationWeight3"
                  value={row.destinationWeight3}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle} style={{ color: "green" }}>Average Weight-IV</FormLabel>
                )}
                <Input
                  type="text"
                  name="averageWeight4"
                  value={row.averageWeight4}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="green"
                  borderColor={"green"}
                  bgColor={"green.100"}
                />
              </Box>
              <Box>
                {index === 0 && (
                  <FormLabel {...FormLabelStyle}>
                    Material Received Date
                  </FormLabel>
                )}
                <Input
                  type="date"
                  name="materialReceivedDate"
                  value={formatDate(row.materialReceivedDate)}
                  // value={row.materialReceivedDate}
                  onChange={(e) => handleInputChangeRowsWeightLevel3(e, index)}
                  focusBorderColor="teal.400"
                  borderColor="gray.400"
                />
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"space-around"} mt={5} pl={35} pr={35}>
            <Input
              type="text"
              name="vehicleNumber"
              value="Total"
              focusBorderColor="teal.400"
              borderColor="gray.400"
              isReadOnly
            />
            <Input
              type="text"
              name="noOfPackageLoad"
              value={totalsWeightLevel3.noOfPackageLoad}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="destinationWeight1"
              value={totalsWeightLevel3.destinationWeight1}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="destinationWeight2"
              value={totalsWeightLevel3.destinationWeight2}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="destinationWeight3"
              value={totalsWeightLevel3.destinationWeight3}
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
            />
            <Input
              type="text"
              name="averageWeight4"
              value={totalsWeightLevel3.averageWeight4}
             
              isReadOnly
              focusBorderColor="green"
              borderColor={"green"}
              bgColor={"green.100"}
            />
            <Input
              type="text"
              name="materialReceivedDate"
              value=""
              focusBorderColor="teal.400"
              isReadOnly
              borderColor="gray.400"
              visibility={"hidden"}
            />
          </Flex>
          <Flex justifyContent={"flex-end"} gap={2} wrap="wrap">
            <Button
              onClick={addNewRowWeightLevel3}
              mt={5}
              color="white"
              backgroundColor="teal.400"
              borderRadius="15"
              _hover={{ backgroundColor: "green.200", color: "black" }}
            >
              +
            </Button>
            <IconButton
              onClick={removeNewRowWeightLevel3}
              color="white"
              backgroundColor="red"
              borderRadius="15"
              mt={5}
              _hover={{ backgroundColor: "red.400", color: "black" }}
              icon={<MdDeleteForever />}
              aria-label="Remove"
              isDisabled={rowsWeightLevel3.length <= 1}
            />
          </Flex>
        </Box>
        <Flex justifyContent={"flex-start"}>
          <Heading
            as="h6"
            size="xs"
            textAlign={"start"}
            textDecoration={"underline"}
            mb={5}
          >
            Weight Summary
          </Heading>
        </Flex>
        <Table variant="simple" size="sm" border={"1px solid gray"}>
          <Thead border={"1px solid gray"}>
            <Tr border={"1px solid gray"}>
              <Th textAlign="center" border={"1px solid gray"}></Th>
              <Th colSpan={2} textAlign="center" border={"1px solid gray"}>
                Weight Details
              </Th>
              <Th colSpan={2} textAlign="center" border={"1px solid gray"}>
                Shortage Details
              </Th>
            </Tr>
            <Tr border={"1px solid gray"}>
              <Th textAlign="center" border={"1px solid gray"}>
                Particulars
              </Th>
              <Th textAlign="center" border={"1px solid gray"}>
                Weight
              </Th>
              <Th textAlign="center" border={"1px solid gray"}>
                Packages
              </Th>
              <Th textAlign="center" border={"1px solid gray"}>
                Weight
              </Th>
              <Th textAlign="center" border={"1px solid gray"}>
                Packages
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr border={"1px solid gray"}>
              <Td
                border={"1px solid gray"}
                style={{ textAlign: "start", fontWeight: "bold" }}
                {...FormLabelStyle}
              >
                Invoice Weight & Package
              </Td>
              <Td border={"1px solid gray"}>{weightSummaryData.invoiceWD}</Td>
              <Td border={"1px solid gray"}>{weightSummaryData.invoicePD}</Td>
              <Td border={"1px solid gray"}>{weightSummaryData.invoiceSW}</Td>
              <Td border={"1px solid gray"}>{weightSummaryData.invoiceSP}</Td>
            </Tr>
            <Tr border={"1px solid gray"}>
              <Td
                border={"1px solid gray"}
                style={{ textAlign: "start", fontWeight: "bold" }}
                {...FormLabelStyle}
              >
                CFS Weight Average
              </Td>
              <Td border={"1px solid gray"}>{weightSummaryData.cfsWD}</Td>
              <Td border={"1px solid gray"}>{weightSummaryData.cfsPD}</Td>
              <Td border={"1px solid gray"}>{weightSummaryData.cfsSW}</Td>
              <Td border={"1px solid gray"}>{weightSummaryData.cfsSP}</Td>
            </Tr>
            <Tr border={"1px solid gray"}>
              <Td
                border={"1px solid gray"}
                style={{ textAlign: "start", fontWeight: "bold" }}
                {...FormLabelStyle}
              >
                Loading Weight Average
              </Td>
              <Td border={"1px solid gray"}>{weightSummaryData.loadingWD}</Td>
              <Td border={"1px solid gray"}>{weightSummaryData.loadingPD}</Td>
              <Td border={"1px solid gray"}>{weightSummaryData.loadingSW}</Td>
              <Td border={"1px solid gray"}>{weightSummaryData.loadingSP}</Td>
            </Tr>
            <Tr border={"1px solid gray"}>
              <Td
                border={"1px solid gray"}
                style={{ textAlign: "start", fontWeight: "bold" }}
                {...FormLabelStyle}
              >
                Destination Weight Average
              </Td>
              <Td border={"1px solid gray"}>
                {weightSummaryData.destinationWD}
              </Td>
              <Td border={"1px solid gray"}>
                {weightSummaryData.destinationPD}
              </Td>
              <Td border={"1px solid gray"}>
                {weightSummaryData.destinationSW}
              </Td>
              <Td border={"1px solid gray"}>
                {weightSummaryData.destinationSP}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </FormControl>
    </Box>
  );

  const [costing, setCosting] = useState({
    p2sbasic1: "",
    p2stax1: "",
    p2sremarks1: "",
    p2sbasic2: "",
    p2stax2: "",
    p2sremarks2: "",
    bankChargesbasic: "",
    bankChargestax: "",
    bankChargesremarks: "",
    bankInterestbasic: "",
    bankInteresttax: "",
    bankInterestremarks: "",
    cacbasic1: "",
    cactax1: "",
    cacremarks1: "",
    cfsChargesbasic: "",
    cfsChargestax: "",
    cfsChargesremarks: "",
    shipingChargesbasic: "",
    shipingChargestax: "",
    shipingChargesremarks: "",
    damageChargesbasic: "",
    damageChargestax: "",
    damageChargesremarks: "",
    detentionChargesbasic: "",
    detentionChargestax: "",
    detentionChargesremarks: "",
    customDutybasic: "",
    customDutytax: "",
    customDutyremarks: "",
    welfarebasic: "",
    welfaretax: "",
    welfareremarks: "",
    igstbasic: "",
    igsttax: "",
    igstremarks: "",
    additionalExpensebasic: "",
    additionalExpensetax: "",
    additionalExpenseremarks: "",
    totalAmountbasic: "",
    totalAmounttax: "",
    totalAmountremarks: "",
    itemName: rows.map((row) => row.itemName).join(", "),
    totalInvoicebasic: "",
    totalInvoicetax: "",
    totalInvoiceremarks: "",
    actualAvgbasic: "",
    actualAvgtax: "",
    actualAvgremarks: "",
  });

  // Use useEffect to load the contract data into the state
  useEffect(() => {
    if (contract && contract.costing) {
      setCosting(contract.costing);
    }
  }, [contract]); // Trigger this when the contract changes

  const handleInputChangeCosting = (e) => {
    const { name, value } = e.target;

    setCosting((prevCosting) => {
      const updatedCosting = {
        ...prevCosting,
        [name]: value,
      };

      // Recalculate values
      const totalAmountbasic = calculateTotalAmountBasic(updatedCosting);
      const totalInvoicebasic =
        Number(totalsWeightLevel1.invoiceNetWeight) || 0; // Ensure it's a number
      const actualAvgbasic = Number(totalsWeightLevel3.averageWeight4) || 0; // Ensure it's a number

      const totalAmounttax = calculateTotalAmountTax(updatedCosting);

      // Recalculate and guard against division by zero
      const totalInvoiceremarks =
        totalInvoicebasic !== 0
          ? calculateTotalInvoiceQty(totalAmountbasic, totalInvoicebasic)
          : 0;
      const actualAvgremarks =
        actualAvgbasic !== 0
          ? calculateTotalActualQty(totalAmountbasic, actualAvgbasic)
          : 0;

      return {
        ...updatedCosting,
        totalAmountbasic: totalAmountbasic.toFixed(2),
        totalInvoicebasic: totalInvoicebasic.toFixed(2),
        actualAvgbasic: actualAvgbasic.toFixed(2),
        totalAmounttax: totalAmounttax.toFixed(2),
        totalInvoiceremarks: totalInvoiceremarks.toFixed(2),
        actualAvgremarks: actualAvgremarks.toFixed(2),
      };
    });
  };

  const calculateTotalAmountBasic = (costing) => {
    return [
      Number(costing.p2sbasic1 || 0),
      Number(costing.p2sbasic2 || 0),
      Number(costing.bankChargesbasic || 0),
      Number(costing.bankInterestbasic || 0),
      Number(costing.cacbasic1 || 0),
      Number(costing.cfsChargesbasic || 0),
      Number(costing.shipingChargesbasic || 0),
      Number(costing.damageChargesbasic || 0),
      Number(costing.detentionChargesbasic || 0),
      Number(costing.customDutybasic || 0),
      Number(costing.welfarebasic || 0),
      Number(costing.igstbasic || 0),
      Number(costing.additionalExpensebasic || 0),
    ].reduce((acc, curr) => acc + curr, 0); // sum all basic values
  };

  // Function to calculate totalInvoicebasic (totalAmountbasic * totalInvoiceremarks)

  const calculateTotalAmountTax = (costing) => {
    return [
      Number(costing.p2stax1 || 0),
      Number(costing.p2stax2 || 0),
      Number(costing.bankChargestax || 0),
      Number(costing.bankInteresttax || 0),
      Number(costing.cactax1 || 0),
      Number(costing.cfsChargestax || 0),
      Number(costing.shipingChargestax || 0),
      Number(costing.damageChargestax || 0),
      Number(costing.detentionChargestax || 0),
      Number(costing.customDutytax || 0),
      Number(costing.welfaretax || 0),
      Number(costing.igsttax || 0),
      Number(costing.additionalExpensetax || 0),
    ].reduce((acc, curr) => acc + curr, 0); // sum all basic values
  };

  const calculateTotalInvoiceQty = (totalAmountbasic, totalInvoicebasic) => {
    return Number(totalAmountbasic) / Number(totalInvoicebasic);
  };

  const calculateTotalActualQty = (totalAmountbasic, actualAvgbasic) => {
    return Number(totalAmountbasic) / Number(actualAvgbasic);
  };

  const renderCostingSummary = () => (
    <Box>
      <FormControl>
        <Box
          w={{ base: "100%", md: "100%" }}
          p={{ base: "5", md: "5" }}
          minH={750}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"sm"}>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  ></Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Basic
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Tax Amount
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Remarks
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Payment to Shipper-I [INR]
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="p2sbasic1"
                      value={costing.p2sbasic1 || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="p2stax1"
                      value={costing.p2stax1 || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="p2sremarks1"
                      value={costing.p2sremarks1 || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Payment to Shipper-II [INR]
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="p2sbasic2"
                      value={costing.p2sbasic2 || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="p2stax2"
                      value={costing.p2stax2 || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="p2sremarks2"
                      value={costing.p2sremarks2 || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Bank Charges
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="bankChargesbasic"
                      value={costing.bankChargesbasic || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="bankChargestax"
                      value={costing.bankChargestax || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="bankChargesremarks"
                      value={costing.bankChargesremarks || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Bank Interest
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="bankInterestbasic"
                      value={costing.bankInterestbasic || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="bankInteresttax"
                      value={costing.bankInteresttax || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="bankInterestremarks"
                      value={costing.bankInterestremarks || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Clearing Agent Charges-I [INR]
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="cacbasic1"
                      value={costing.cacbasic1 || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="cactax1"
                      value={costing.cactax1 || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="cacremarks1"
                      value={costing.cacremarks1 || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    CFS Charges
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="cfsChargesbasic"
                      value={costing.cfsChargesbasic || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="cfsChargestax"
                      value={costing.cfsChargestax || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="cfsChargesremarks"
                      value={costing.cfsChargesremarks || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Shipping Charges-I
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="shipingChargesbasic"
                      value={costing.shipingChargesbasic || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="shipingChargestax"
                      value={costing.shipingChargestax || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="shipingChargesremarks"
                      value={costing.shipingChargesremarks || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Damage Charges-I
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="damageChargesbasic"
                      value={costing.damageChargesbasic || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="damageChargestax"
                      value={costing.damageChargestax || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="damageChargesremarks"
                      value={costing.damageChargesremarks || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Detention Charges
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="detentionChargesbasic"
                      value={costing.detentionChargesbasic || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="detentionChargestax"
                      value={costing.detentionChargestax || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="detentionChargesremarks"
                      value={costing.detentionChargesremarks || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Basic Custom Duty
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="customDutybasic"
                      value={costing.customDutybasic || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="customDutytax"
                      value={costing.customDutytax || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="customDutyremarks"
                      value={costing.customDutyremarks || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Social Welfare Surcharge
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="welfarebasic"
                      value={costing.welfarebasic || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="welfaretax"
                      value={costing.welfaretax || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="welfareremarks"
                      value={costing.welfareremarks || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    IGST Tax Amount
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="igstbasic"
                      value={costing.igstbasic || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="igsttax"
                      value={costing.igsttax || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="igstremarks"
                      value={costing.igstremarks || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Additional Expenses
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="additionalExpensebasic"
                      value={costing.additionalExpensebasic || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="additionalExpensetax"
                      value={costing.additionalExpensetax || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="additionalExpenseremarks"
                      value={costing.additionalExpenseremarks || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Total Amount [INR]
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="totalAmountbasic"
                      value={costing.totalAmountbasic || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="totalAmounttax"
                      value={costing.totalAmounttax || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="totalAmountremarks"
                      value={costing.totalAmountremarks || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Item Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="itemName"
                      value={
                        costing.itemName ||
                        rows.map((row) => row.itemName).join(", ")
                      }
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    -
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Cost Per Kgs
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Total Invoice Quantity
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="totalInvoicebasic"
                      value={costing.totalInvoicebasic || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="totalInvoicetax"
                      value={costing.totalInvoicetax || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="totalInvoiceremarks"
                      value={costing.totalInvoiceremarks || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Actual Average Qty Received
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="actualAvgbasic"
                      value={costing.actualAvgbasic || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="actualAvgtax"
                      value={costing.actualAvgtax || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder=""
                      name="actualAvgremarks"
                      value={costing.actualAvgremarks || ""}
                      onChange={handleInputChangeCosting}
                      {...SubInputStyle}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
        </Box>
      </FormControl>
    </Box>
  );

  const ThLabelStyle = {
    textAlign: "center",
    color: "teal",
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "120px",
    fontSize: "10px",
  };

  const combineData = () => {
    return rowsWeightLevel1.map((row1, index) => {
      const row2 = rowsWeightLevel2[index] || {};
      const row3 = rowsWeightLevel3[index] || {};
      return {
        containerNumber: row1.containerNumber,
        containerWiseNetQty: row1.invoiceNetWeight,
        noOfBlockPerBundle: row1.noOfPackage,
        noOfPCs: row1.noOfPackage, // Adjust as per your data structure
        cfsNetWeight1: row1.cfsWeight1,
        cfsNetWeight2: row1.cfsWeight2,
        vehicleNo: row2.vehicleNumber,
        noOfBlockPerBundleLoad: row2.noOfPackageLoad,
        ewayBillQty: row2.portLoadingWeight1, // Adjust as per your data structure
        ewayBillNo: row2.ewayBillNo,
        ewayBillDate: row2.dispatchDate,
        portLoadingWeight1: row2.portLoadingWeight1,
        portLoadingWeight2: row2.portLoadingWeight2,
        deliveryLocationWeight1: row3.destinationWeight1,
        deliveryLocationWeight2: row3.destinationWeight2,
        deliveryLocationWeight3: row3.destinationWeight3,
        deliveryLocationAvgWeight2: row3.averageWeight4,
        materialReceivedDate: row3.materialReceivedDate,
      };
    });
  };

  const combinedData = combineData();

  // console.log("combinedData",combinedData)
  const renderShipmentSummary = () => (
    <Box>
      <FormControl>
        <Box
          w={{ base: "100%", md: "100%" }}
          p={{ base: "5", md: "5" }}
          minH={750}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"sm"}>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "start", color: "teal" }}
                  >
                    Shipment Details
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Important Names
                  </Th>
                  <Th
                    boxShadow={"sm"}
                    style={{ textAlign: "center", color: "teal" }}
                  >
                    Important Dates
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Importer Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {contract?.salesContract
                      ? contract?.salesContract?.importingFirmName
                      : ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Exporter Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.exporterName || contract.exporterName}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Sales Contract
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {contract.salesContractNumber || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>

                {contract.typeOfImport === "High Sea Sale Import" && (
                  <>
                    <Tr boxShadow={"md"}>
                      <Td
                        style={{
                          textAlign: "start",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        HSS Seller Invoice No
                      </Td>
                      <Td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        {shipmentWiseData.hssSellerInvoiceNo || ""}
                      </Td>
                      <Td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      ></Td>
                    </Tr>
                  </>
                )}

                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Country Of Origin
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {/* {value.label} */}
                    {contract?.shipmentWiseData
                      ? contract?.shipmentWiseData?.shipmentcountry
                      : ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>

                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Type Of Duty
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.typeOfDuty || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Export Invoice Value (In USD)
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {contract?.paymentStatusData?.shipperInvoiceAmount != null
                      ? contract?.paymentStatusData?.shipperInvoiceAmount
                      : contract?.paymentStatusData?.shipperInvoiceCurrency}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>

                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Bill Of Lending No
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.lendingNo || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Shipping Line Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.shippingLine || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Clearing Agent Name
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.clearingHouseAgentName || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>

                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Bill Of Entry
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {customerClearingStatusData.beNo || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {formatDate(customerClearingStatusData.beDate) || ""}
                  </Td>
                </Tr>
                <Tr boxShadow={"md"}>
                  <Td
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Exporter Invoice No & Date
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    {shipmentWiseData.exporterInvoiceNo || ""}
                  </Td>
                  <Td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  ></Td>
                </Tr>
              </Tbody>
            </Table>
            <Box>
              {paginationData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>

          <TableContainer>
            <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
              <Thead>
                <Tr boxShadow={"sm"}>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Sl No
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Container No
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Container Wise Net Qty (in Kgs)
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    No Of Blocks / Bundle
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    No Of Pcs
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    CFS Net Weight-I
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    CFS Net Weight-II
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Vehicle No
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    No Of Blocks / Bundle Load
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Eway Bill Qty
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Eway Bill No
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Eway Bill Date
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Port Loading Weight-I
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Port Loading Weight-II
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Delivery Location Weight-I
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Delivery Location Weight-II
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Delivery Location Weight-III
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Delivery Location Average Weight
                  </Th>
                  <Th boxShadow={"sm"} {...ThLabelStyle}>
                    Material Received Date
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {combinedData.length > 0 &&
                  combinedData.map((el, index) => (
                    <Tr key={el.slNo} boxShadow={"md"}>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{index + 1}</Button>
                      </Td>

                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.containerNumber}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.containerWiseNetQty}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.noOfBlockPerBundle}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.noOfPCs}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.cfsNetWeight1}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.cfsNetWeight2}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.vehicleNo}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.noOfBlockPerBundleLoad}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.ewayBillQty}</Button>
                      </Td>

                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>{el.ewayBillNo}</Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {/* {format(new Date(el.ewayBillDate), "dd-MM-yyyy")} */}
                          {el?.ewayBillDate
    ? format(new Date(el.ewayBillDate), "dd-MM-yyyy")
    : "N/A"}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.portLoadingWeight1}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.portLoadingWeight2}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.deliveryLocationWeight1}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.deliveryLocationWeight2}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.deliveryLocationWeight3}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {el.deliveryLocationAvgWeight2}
                        </Button>
                      </Td>
                      <Td style={{ textAlign: "center" }}>
                        <Button {...ButtonStyle}>
                          {/* {format(
                            new Date(el.materialReceivedDate),
                            "dd-MM-yyyy"
                          )} */}
                           {el.materialReceivedDate
    ? format(new Date(el?.materialReceivedDate), "dd-MM-yyyy")
    : "N/A"}
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <Box>
              {combinedData.length === 0 && (
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                >
                  <AlertIcon boxSize={8} />
                  No Record Found
                </Alert>
              )}
            </Box>
          </TableContainer>
        </Box>
      </FormControl>
    </Box>
  );

  // Rendering Each Tabs End Here //

  const renderShipmentDetailsForm = () => (
    <Box
      w={{ base: "100%", md: "100%" }}
      p={{ base: "5", md: "5" }}
      minH={750}
      borderRadius={10}
      boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
    >
      <Flex justifyContent={"space-between"} pl={5} pr={5} pt={1}>
        <Flex justifyContent={"flex-start"} gap={5}>
          <IoMdArrowRoundBack
            onClick={() => navigation("/salescontract")}
            size={20}
            style={{ marginTop: 5 }}
          />
          <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
            Edit Shipment Details Of Contract No :{" "}
            <span style={{ color: "black" }}>
              {contract?.salesContractNumber}
            </span>
          </Heading>
        </Flex>

        <Flex justifyContent={"space-between"} gap={5}>
          <Button
            color="white"
            backgroundColor="teal.400"
            borderRadius="15"
            onClick={() => handleEdit(contract._id)}
            _hover={{ backgroundColor: "green.200", color: "black" }}
          >
            Save Shipment
          </Button>
        </Flex>
      </Flex>
      <Tabs variant="soft-rounded" colorScheme="teal">
        <TabList>
          <Tab>Shipment Wise Details</Tab>
          <Tab>Documents Status</Tab>
          <Tab>Payment Status</Tab>
          <Tab>Customer Clearing Status</Tab>
          <Tab>Weight Summary Status</Tab>
          <Tab>Costing</Tab>
          <Tab>Shipment Summary</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>{renderShipmentWise()}</TabPanel>
          <TabPanel>{renderDocumentStatus()}</TabPanel>
          <TabPanel>{renderPaymentStatus()}</TabPanel>
          <TabPanel>{renderCustomClearingStatus()}</TabPanel>
          <TabPanel>{renderWeightSummary()}</TabPanel>
          <TabPanel>{renderCostingSummary()}</TabPanel>
          <TabPanel>{renderShipmentSummary()}</TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );

  return (
    <Box
      pl={{ base: "2", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}
    >
      <Navbar />
      <br />
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        {view === "shipmentdetailsform" && renderShipmentDetailsForm()}
      </Flex>
    </Box>
  );
};

export default EditShipmentDetails;
