import axios from "axios";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {backendBaseURL} from '../AppConstants';
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [fetchexporterNamesList, setexporterNamesList] = useState([]);
  const [fetchimporterNamesList, setimporterNamesList] = useState([]);
  const [fetchclearingAgentNamesList, setclearingAgentNamesList] = useState([]);
  const [fetchbankNamesList, setbankNamesList] = useState([]);
  const [fetchitemNamesssList, setitemNamesList] = useState([]);
  const [fetchfinancerNamesList, setfinancerNamesList] = useState([]);
  const [fetchshippingLinesList, setshippingLinesList] = useState([]);
  const [fetchtransporterNamesList, settransporterNamesList] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [shippingDetailsData, setShippingDetailsData] = useState([]);
  const [fetchfinanceGenerationNamesList, setfinanceGenerationNamesList] = useState([]);
  const [fetchrePaymentList, setrePaymentList] = useState([]);
     //console.log("fetchexporterNamesList", fetchexporterNamesList);
  //   console.log("importerNamesList", fetchimporterNamesList);
  //   console.log("clearingAgentNamesList", fetchclearingAgentNamesList);
  //   console.log("bankNamesList", fetchbankNamesList);
  //   console.log("itemNamesssList", fetchitemNamesList);
  //   console.log("shippingDetailsData", shippingDetailsData);

  const fetchData = useCallback(() => {
    const apiUrl1 = `${backendBaseURL}/getExporterDetails`;
    const apiUrl2 = `${backendBaseURL}/getImporterDetails`;
    const apiUrl3 = `${backendBaseURL}/getClearingAgentDetails`;
    const apiUrl4 = `${backendBaseURL}/getBankDetails`;
    const apiUrl5 = `${backendBaseURL}/getItemNameDetails`;
    const apiUrl6 = `${backendBaseURL}/getFinancerDetails`;
    const apiUrl7 = `${backendBaseURL}/getSalesContractDetails`;
    const apiUrl8 = `${backendBaseURL}/getShippingDetailsData`;
    const apiUrl9 = `${backendBaseURL}/getShippingLineDetails`;
    const apiUrl10 = `${backendBaseURL}/getTransporterNameDetails`;
    const apiUrl11 = `${backendBaseURL}/getFinanceGenerationDetails`;
    const apiUrl12 = `${backendBaseURL}/getrePaymentDetails`;
    axios
      .all([
        axios.get(apiUrl1),
        axios.get(apiUrl2),
        axios.get(apiUrl3),
        axios.get(apiUrl4),
        axios.get(apiUrl5),
        axios.get(apiUrl6),
        axios.get(apiUrl7),
        axios.get(apiUrl8),
        axios.get(apiUrl9),
        axios.get(apiUrl10),
        axios.get(apiUrl11),
        axios.get(apiUrl12),
      ])
      .then(
        axios.spread(
          (
            response1,
            response2,
            response3,
            response4,
            response5,
            response6,
            response7,
            response8,
            response9,
            response10,
            response11,
            response12,
          ) => {
            setexporterNamesList(response1.data);
            setimporterNamesList(response2.data);
            setclearingAgentNamesList(response3.data);
            setbankNamesList(response4.data);
            setitemNamesList(response5.data);
            setfinancerNamesList(response6.data);
            setPaginationData(response7.data);
            setShippingDetailsData(response8.data);
            setshippingLinesList(response9.data);
            settransporterNamesList(response10.data);
            setfinanceGenerationNamesList(response11.data);
            setrePaymentList(response12.data);
          }
        )
      )
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);



  const exporterNamesList = Array.from(
    new Set(fetchexporterNamesList.map((data) => data.exporterName))
  );
  const importerFirmNamesList = Array.from(
    new Set(fetchimporterNamesList.map((data) => data.importerName))
  );
  const itemNamesList = Array.from(
    new Set(fetchitemNamesssList.map((data) => data.itemName))
  );
  const bankNamesList = Array.from(
    new Set(fetchbankNamesList.map((data) => data.bankName))
  );
  const financerNamesList = Array.from(
    new Set(fetchfinancerNamesList.map((data) => data.financerOrganizationName))
  );
  const clearingAgentsList = Array.from(
    new Set(fetchclearingAgentNamesList.map((data) => data.agentCompanyName))
  );
  const shippingLinesList = Array.from(
    new Set(fetchshippingLinesList.map((data) => data.shippingName))
  );
  const transporterNamesList = Array.from(
    new Set(fetchtransporterNamesList.map((data) => data.transporterName))
  );

  const salesContractNumberList = Array.from(
    new Set(paginationData.map((data) => data.salesContractNumber))
  );


  const [email, setEmail] = useState("");
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  //console.log("token",token)
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  
  const handleLoginwithAuth = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${backendBaseURL}/login`, { loginId, password });
      if (response.data.token) {
        // Save token to localStorage for persistence
        localStorage.setItem("token", response.data.token);
        setToken(response.data.token);
  
        // Replace login in history to prevent navigating back
        navigate("/home", { replace: true });
  
        toast({
          position: "top",
          title: "Successfully Logged In",
          description: `${loginId} has been successfully logged in`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
  
        setLoginId("");
        setPassword("");
      } else {
        toast({
          position: "top",
          title: "Incorrect Credentials",
          description: "Incorrect Credentials",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Login failed:", error.response.data.message);
      toast({
        position: "top",
        title: `${error.response.data.message}`,
        description: "An error occurred during login. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  
  

  const value = {
    fetchexporterNamesList,
    fetchimporterNamesList,
    fetchclearingAgentNamesList,
    fetchbankNamesList,
    fetchitemNamesssList,
    fetchfinancerNamesList,
    fetchfinanceGenerationNamesList,
    fetchrePaymentList,
    fetchshippingLinesList,
    fetchtransporterNamesList,
    exporterNamesList,
    importerFirmNamesList,
    itemNamesList,
    bankNamesList,
    financerNamesList,
    clearingAgentsList,
    shippingLinesList,
    transporterNamesList,
    paginationData,
    salesContractNumberList,
    shippingDetailsData,
    email, setEmail,password, setPassword,loading, setLoading,handleLoginwithAuth,token,setToken,
    loginId, setLoginId
 
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
