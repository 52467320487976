import {
    Alert,
    AlertIcon,
    Box,
    Button,
  
    Flex,
    Heading,
    Input,
 
    Table,
    TableContainer,
    Tbody,
    Td,
 
    Th,
    Thead,
    Tr,
  
  } from "@chakra-ui/react";
  import React, { useState } from "react";
  import { Pagination } from "../../../Components/Pagination";
  import Navbar from "../../../Components/Navbar";
 
  import { useAuth } from "../../../Context/AuthProvider";
import { format } from "date-fns";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
  
  const RepaymentLists = () => {
    const ButtonStyle = {
      size: "xs",
      fontWeight: "bold",
      backgroundColor: "white",
      _hover: { backgroundColor: "white" },
    };
  
    const { fetchrePaymentList } =
      useAuth();
  
const navigate = useNavigate();
  
    const [page, setPage] = useState(1);
    let perPage = 10;
    let totalPage = Math.ceil(fetchrePaymentList.length / perPage);
    let end = page * perPage;
    let start = end - perPage;
    let reverseData = [...fetchrePaymentList].slice(start, end);
    const [searchQuery, setSearchQuery] = useState("");
    const filteredData = reverseData.filter((el) => {
      
      const financerOrganizationName = el?.financerOrganizationName || "";
      const transactionRefNo = el?.transactionRefNo || "";
      const paymentThrough =
       el?.paymentThrough || "";
      const netAmountPayable =
        el?.netAmountPayable || "";
      const totalAmount = el?.totalAmount || "";
      return (
        
        financerOrganizationName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        transactionRefNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
        paymentThrough.toLowerCase().includes(searchQuery.toLowerCase()) ||
        netAmountPayable
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
          totalAmount.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  
    return (
      <Box
        pl={{ base: "2", md: "10" }}
        pt={{ base: "10", md: "10" }}
        w={{ base: "100%", md: "80%" }}
      >
        <Navbar />
        <br />
  
       
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
          mb={5}
        >
         <Flex justifyContent={"flex-start"} gap={5}>
              <IoMdArrowRoundBack
                onClick={() => navigate("/finance")}
                size={20}
                style={{ marginTop: 5 }}
              />
              <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
                Repayment List
              </Heading>
            </Flex>
          <Input
            placeholder="Search"
            w={300}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Flex>
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
        >
          <Box
            w={{ base: "100%", md: "100%" }}
            p={{ base: "5", md: "5" }}
            
            borderRadius={10}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          >
            <TableContainer>
              <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                <Thead>
                  <Tr boxShadow={"sm"}>
                 
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Financer Name
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Transaction Ref No
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Net Amount Payable
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Additional Charges
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Total Amount
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Payment Through
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Repayment Date
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Currency
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Exchange Rate
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Amount In INR
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                     Bank Charges
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {filteredData.length > 0 &&
                    filteredData?.map((el) => (
                      <Tr key={el._id} boxShadow={"md"}>
                        
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>
                            {el.financerOrganizationName}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>
                            {el.transactionRefNo}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.netAmountPayable}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>
                            {el.additionalCharges}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.totalAmount}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.paymentThrough}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{format(new Date(el.repaymentDate), "dd-MM-yyyy")}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.repaymentCurrency}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.reExchangeRate}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.amountInINR}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.bankCharges}</Button>
                        </Td>
                        
                      </Tr>
                    ))}
                </Tbody>
              </Table>
           
              <Box>
                {filteredData.length === 0 && (
                  <Alert
                    status="info"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    mt={4}
                  >
                    <AlertIcon boxSize={8} />
                    No Record Found
                  </Alert>
                )}
              </Box>
            </TableContainer>
            <Flex justifyContent={"flex-end"} mt={5}>
              <Pagination page={page} setPage={setPage} totalPage={totalPage} />
            </Flex>
          </Box>
        </Flex>
      </Box>
    );
  };
  
  export default RepaymentLists;
  