import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminLogin from "../Logins/AdminLogin";
import Dashboard from "../Pages/Admin/Dashboard/Dashboard";
import Home from "../Pages/Admin/Home/Home";
import SalescontractDetails from "../Pages/Admin/SalesContract/SalescontractDetails";
import MasterCreation from "../Pages/Admin/MasterCreation/MasterCreation";
import SalesContract from "../Pages/Admin/SalesContract/SalesContract";
import Finance from "../Pages/Admin/Finance/Finance";
import FinanceDash from "../Pages/Admin/Finance/FinanceDash";
import AddMoreShipment from "../Pages/Admin/SalesContract/AddMoreShipment";
import EditShipment from "../Pages/Admin/SalesContract/EditShipment";
import RequiredAuth from "../PrivateRoutes/RequiredAuth";
import Repayment from "../Pages/Admin/Finance/Repayment";


const AllRoutes = () => {
  return (
    <Routes>
      {/* Public route */}
      <Route path="/" element={<AdminLogin />} />

      {/* Protected routes wrapped in RequiredAuth */}
      <Route
        path="/home"
        element={
          <RequiredAuth>
            <Home />
          </RequiredAuth>
        }
      />
      <Route
        path="/dashboard"
        element={
          <RequiredAuth>
            <Dashboard />
          </RequiredAuth>
        }
      />
      <Route
        path="/salescontract"
        element={
          <RequiredAuth>
            <SalesContract />
          </RequiredAuth>
        }
      />
      <Route
        path="/finance"
        element={
          <RequiredAuth>
            <Finance />
          </RequiredAuth>
        }
      />
      <Route
        path="/financedashboard"
        element={
          <RequiredAuth>
            <FinanceDash />
          </RequiredAuth>
        }
      />
      <Route
        path="/repayment"
        element={
          <RequiredAuth>
            <Repayment />
          </RequiredAuth>
        }
      />
      <Route
        path="/salescontractdetails"
        element={
          <RequiredAuth>
            <SalescontractDetails />
          </RequiredAuth>
        }
      />
      <Route
        path="/addmoreshipment"
        element={
          <RequiredAuth>
            <AddMoreShipment />
          </RequiredAuth>
        }
      />
      <Route
        path="/editshipment"
        element={
          <RequiredAuth>
            <EditShipment />
          </RequiredAuth>
        }
      />
      <Route
        path="/mastercreation"
        element={
          <RequiredAuth>
            <MasterCreation />
          </RequiredAuth>
        }
      />
    </Routes>
  );
};

export default AllRoutes;
