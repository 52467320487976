import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Box,
  Flex,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useAuth } from "../Context/AuthProvider";

const AdminLogin = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const {
    email,
    setEmail,
    loginId, setLoginId,
    password,
    setPassword,
    loading,
    setLoading,
    handleLoginwithAuth,
  } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/home", { replace: true });
    }
  }, [navigate]);

  const handleForgetPassword = async () => {
    toast({
      position: "top",
      title: "Redirecting to password reset email link",
      status: "warning",
      duration: 3000,
      isClosable: true,
    });
    setTimeout(() => {
      navigate("/set-email");
    }, 3000);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLoginwithAuth();
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Flex
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
      px={{ base: 4, sm: 6, md: 8 }} // Adjust padding based on screen size
    >
      <VStack spacing={4} align="stretch" w="100%" maxW="md">
        <Box p={8} borderWidth={1} borderRadius={8} boxShadow="lg" w="100%">
          <FormControl id="loginId">
            <FormLabel>User Id</FormLabel>
            <Input
              type="text"
              placeholder="Enter your User Id"
              value={loginId}
              onChange={(e) => setLoginId(e.target.value)}
              required
              onKeyPress={handleKeyPress}
            />
          </FormControl>

          <FormControl mt={4} id="password">
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                onKeyPress={handleKeyPress}
              />
              <InputRightElement width="2.5rem">
                <IconButton
                  h="1.75rem"
                  size="sm"
                  onClick={handleTogglePassword}
                  icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Button
            mt={2}
            // onClick={handleForgetPassword}
            variant="link"
            color="teal.500"
            textAlign="left"
            w="100%"
          >
            Forgot Password?
          </Button>
          <Button
            mt={4}
            onClick={handleLoginwithAuth}
            bgGradient="linear(to-r, teal.500, teal.100)"
            _hover={{ bgGradient: "linear(to-r, teal.100, teal.500)" }}
            w="100%"
            isLoading={loading}
            loadingText="Logging In"
          >
            Login
          </Button>
        </Box>
        {/* <Button
          mt={2}
          onClick={() => navigate("/home")}
          variant="link"
          color="teal.500"
          textAlign="left"
          w="100%"
        >
          Let's Start Demo
        </Button> */}
      </VStack>
    </Flex>
  );
};

export default AdminLogin;
