import { Box, Image, Stack, Button, useToast } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import iemlogo from "../assets/iemlogo.png";
import iemlogo1 from "../assets/iemlogo1.jpg";
import { IoPieChart } from "react-icons/io5";
import { useAuth } from "../Context/AuthProvider";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {token,setToken} = useAuth();
  const isActive = (pathnames) => {
    return pathnames.includes(location.pathname);
  };

  const getButtonStyles = (pathnames) => {
    if (isActive(pathnames)) {
      return {
        color: "white",
        backgroundColor: "teal.400",
        borderRadius: 15,
        width: ["100%", "80%"],
        marginLeft: "20px",
        _hover: {
          backgroundColor: "#6AE7AC",
          color: "black",
        },
      };
    } else {
      return {
        color: "black",
        backgroundColor: "teal.100",
        borderRadius: 15,
        width: ["100%", "80%"],
        _hover: {
          backgroundColor: "teal.400",
          color: "white",
        },
      };
    }
  };

  const toast = useToast();
  
  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem("token");
  
    // Clear the token from state
    setToken(null);
  
    // Display a toast notification
    toast({
      position: "top",
      title: "Logged out",
      description: "You have been successfully logged out.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  
    // Redirect to login page
    navigate("/", { replace: true }); // Using replace to prevent going back to the previous page after logout
  };
  


  return (
    <Box
      p={{ base: "none", md: 5 }}
      w={{ base: "100%", md: "15%" }}
      minH={1000}
      //backgroundColor={"white"}
       bgGradient="linear(to-l, #F5F5F5, #F2F3F4)"
    >
      <Box display={{ base: "none", sm: "block" }}>
        <Link to="/home">
          <Image
            src={iemlogo1}
            width={{ base: "50%", sm: "50%", md: "70%" }}
            height="auto"
            objectFit="cover"
            m={8}
          />
        </Link>
        <Stack direction="column" spacing={8}>
          <Button
            leftIcon={<IoPieChart />}
            variant="solid"
            onClick={() => navigate("/home")}
            {...getButtonStyles(["/home"])}
          >
            Dashboard {"        "}
          </Button>

          <Button
            leftIcon={<IoPieChart />}
            variant="solid"
            onClick={() => navigate("/mastercreation")}
            {...getButtonStyles(["/mastercreation"])}
          >
            Master Creation
          </Button>
          <Button
            leftIcon={<IoPieChart />}
            variant="solid"
            onClick={() => navigate("/finance")}
            {...getButtonStyles(["/finance", "/financedashboard"])}
          >
            Finance
          </Button>
          <Button
            leftIcon={<IoPieChart />}
            variant="solid"
            onClick={() => navigate("/salescontract")}
            {...getButtonStyles(["/salescontract"])}
          >
            Sales Contract
          </Button>
          <Button
            leftIcon={<IoPieChart />}
            variant="solid"
            onClick={() => navigate("/repayment")}
             {...getButtonStyles(["/repayment"])}
          >
            Reports
          </Button>
          <Button
            leftIcon={<IoPieChart />}
            variant="solid"
            // onClick={() => navigate("/home")}
             {...getButtonStyles(["/settings"])}
          >
            Settings
          </Button>
          <Button
            leftIcon={<IoPieChart />}
            variant="solid"
            onClick={handleLogout}
            {...getButtonStyles(["/"])}
          >
            Sign Out
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default Sidebar;
