import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../Context/AuthProvider";
import { useToast } from "@chakra-ui/react";

const RequiredAuth = ({ children }) => {
  const { token } = useAuth();
  const toast = useToast();

  // Check token from localStorage to persist login status across sessions
  const storedToken = localStorage.getItem("token");

  if (!token && !storedToken) {
    toast({
      position: "top",
      title: "Please Log In",
      description: "You need to log in to access this page.",
      status: "warning",
      duration: 3000,
      isClosable: true,
    });

    // Redirect to login page if not authenticated
    return <Navigate to="/" replace />;
  }

  // If token exists in state or localStorage, allow access to children
  return children;
};

export default RequiredAuth;
