import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Select as ChakraSelect,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  Grid,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../Components/Navbar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Context/AuthProvider";
import axios from "axios";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { backendBaseURL } from "../../../AppConstants";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Pagination } from "../../../Components/Pagination";
import { format } from "date-fns";
const FinanceForm = () => {
  const toast = useToast();
  const navigation = useNavigate();
  const navigate = useNavigate();
  const {
    salesContractNumberList,
    financerNamesList,
    fetchfinanceGenerationNamesList,
    shippingDetailsData,
    fetchfinancerNamesList,
    fetchrePaymentList,
    bankNamesList,
  } = useAuth();
  //console.log("shippingDetailsData", shippingDetailsData);
  const [page, setPage] = useState(1);
  let perPage = 5;
  let totalPage = Math.ceil(fetchfinancerNamesList.length / perPage);
  let end = page * perPage;
  let start = end - perPage;
  let reverseData = [...fetchfinancerNamesList].slice(start, end);
  let contractSignedData = [...reverseData].slice(start, end);

  const [pageList, setPageList] = useState(1);
  let perPageList = 10;
  let totalPageList = Math.ceil(
    fetchfinanceGenerationNamesList.length / perPageList
  );
  let endList = pageList * perPageList;
  let startList = endList - perPageList;
  let financeListData = [...fetchfinanceGenerationNamesList].slice(
    startList,
    endList
  );

  const updateFinanceDataWithRepayment = (
    financeListData,
    fetchrePaymentList
  ) => {
    // Helper function to format date into dd-mm-yyyy
    const formatDate = (date) => {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
    };

    // Helper function to calculate date difference in days
    const calculateDaysDifference = (date1, date2) => {
      const d1 = new Date(date1); // Date object
      const d2 = new Date(date2); // Date object
      const timeDiff = d1 - d2; // Date subtraction returns milliseconds
      return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    };

    financeListData.forEach((financeItem) => {
      // Find matching repayment data
      const matchingRepayment = fetchrePaymentList.find(
        (repaymentItem) =>
          repaymentItem.transactionRefNo === financeItem.transactionRefNo &&
          repaymentItem.financerOrganizationName ===
            financeItem.financerOrganizationName
      );

      if (matchingRepayment) {
        // Update creditDueDate with repaymentDate formatted to dd-mm-yyyy
        const repaymentDate = new Date(matchingRepayment.repaymentDate);
        financeItem.creditDueDate = formatDate(repaymentDate); // Format for display

        // Extract original (unformatted) creditTakenDate for calculation
        const originalCreditTakenDate = new Date(financeItem.creditTakenDate);

        // Calculate the credit period in days (repaymentDate - creditTakenDate)
        const creditPeriod = calculateDaysDifference(
          repaymentDate,
          originalCreditTakenDate
        );

        // Update creditPeriod in the financeItem
        financeItem.creditPeriod = creditPeriod;

        // Format creditTakenDate for display after calculations
        financeItem.creditTakenDate = formatDate(originalCreditTakenDate);
      }
    });

    return financeListData;
  };

  // Example usage:
  const updatedFinanceList = updateFinanceDataWithRepayment(
    financeListData,
    fetchrePaymentList
  );

  //console.log(updatedFinanceList);

  // const calculateAmountDifference = () => {
  //   // Map through finance generation list
  //   const differences = fetchfinanceGenerationNamesList.map((financeItem) => {
  //     // Find matching item in rePayment list based on transactionRefNo
  //     const matchingRepaymentItem = fetchrePaymentList.find(
  //       (repaymentItem) =>
  //         repaymentItem.transactionRefNo === financeItem.transactionRefNo
  //     );

  //     // If a match is found, subtract netAmountPayable from exporterInvoiceValue
  //     if (matchingRepaymentItem) {
  //       return {
  //         transactionRefNo: financeItem.transactionRefNo,
  //         difference:
  //           financeItem.exporterInvoiceValue -
  //           matchingRepaymentItem.netAmountPayable,
  //       };
  //     }

  //     // If no match is found, return exporterInvoiceValue as-is
  //     return {
  //       transactionRefNo: financeItem.transactionRefNo,
  //       difference: financeItem.exporterInvoiceValue,
  //     };
  //   });

  //   // Returning the differences array for further use or display
  //   return differences;
  // };

  // // Call the function to get the calculated differences
  // const amountDifferences = calculateAmountDifference();

  // console.log("amountDifferences",amountDifferences);

  const calculateAmountDifference = () => {
    return fetchfinanceGenerationNamesList.map((financeItem) => {
      // Find matching item in rePayment list based on transactionRefNo
      const matchingRepaymentItem = fetchrePaymentList.find(
        (repaymentItem) =>
          repaymentItem.transactionRefNo === financeItem.transactionRefNo
      );

      // Calculate the difference or use exporterInvoiceValue if no match is found
      const difference = matchingRepaymentItem
        ? financeItem.exporterInvoiceValue -
          matchingRepaymentItem.netAmountPayable
        : financeItem.exporterInvoiceValue;

      return {
        transactionRefNo: financeItem.transactionRefNo,
        difference,
      };
    });
  };

  // Call the function to get the calculated differences
  const amountDifferences = calculateAmountDifference();
  console.log("amountDifferences", amountDifferences);

  const [formDataRepayment, setFormDataRepayment] = useState({
    financerOrganizationName: "",
    transactionRefNo: "",
    netAmountPayable: "",
    additionalCharges: "",
    totalAmount: "",
    repaymentDate: "",
    repaymentCurrency: "",
    reExchangeRate: "",
    amountInINR: "",
    bankCharges: "",
    paymentThrough: "",
  });

  useEffect(() => {
    const netAmount = parseFloat(formDataRepayment.netAmountPayable) || 0;
    const additionalCharges =
      parseFloat(formDataRepayment.additionalCharges) || 0;
    const totalAmount = netAmount + additionalCharges;

    setFormDataRepayment((prevFormData) => ({
      ...prevFormData,
      totalAmount: totalAmount.toFixed(2),
    }));
  }, [formDataRepayment.netAmountPayable, formDataRepayment.additionalCharges]);

  // const handleInputChangeRepayment = (e) => {
  //   const { name, value } = e.target;
  //   setFormDataRepayment((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const handleInputChangeRepayment = (e) => {
    const { name, value } = e.target;

    setFormDataRepayment((prevData) => {
      // Update the form data
      const updatedData = {
        ...prevData,
        [name]: value,
      };

      // Calculate Amount in INR if totalAmount and reExchangeRate are present
      const totalAmount = parseFloat(updatedData.totalAmount) || 0;
      const reExchangeRate = parseFloat(updatedData.reExchangeRate) || 0;

      // Calculate amountInINR
      const amountInINR = totalAmount * reExchangeRate;

      return {
        ...updatedData,
        amountInINR: isNaN(amountInINR) ? "" : amountInINR.toFixed(2), // Set amountInINR with 2 decimal places
      };
    });
  };

  const [isLoadingRepayment, setIsLoadingRepayment] = useState(false);

  const handleSubmitRepayment = async () => {
    const currentFinancer = formDataRepayment.financerOrganizationName;

    // Fetch the sanction limit for the current financer
    const sanctionLimit = parseFloat(
      contractSignedData.find(
        (el) => el.financerOrganizationName === currentFinancer
      )?.limitOfFinance || 0
    );

    // Calculate the utilized limit and amount in INR for the current financer
    const utilizedLimit = calculateUtilizedLimit(currentFinancer);
    const amountInINR = calculateAmountInINR(currentFinancer);

    // Calculate the available limit
    const availableLimit = sanctionLimit - utilizedLimit + amountInINR;

    // Calculate the maximum amount that can be added without exceeding the sanction limit
    const maxAddableAmount = sanctionLimit - availableLimit;

    // Validation: Check if the initial available limit exceeds the sanction limit
    if (availableLimit > sanctionLimit) {
      alert(
        `Available limit for ${currentFinancer} already exceeds the sanction limit.`
      );
      return; // Stop execution if limit exceeded
    }

    // Validation: Check if adding the current amount will exceed the sanction limit
    const amountToAdd = parseFloat(formDataRepayment.amountToAdd || 0);
    if (amountToAdd + availableLimit > sanctionLimit) {
      alert(`Adding ${amountToAdd} exceeds the sanction limit for ${currentFinancer}.
  You can add up to ${maxAddableAmount} without exceeding the sanction limit.`);
      return; // Stop execution if limit exceeded
    }

    // Proceed with POST request if all validations pass
    setIsLoadingRepayment(true);
    const apiUrl = `${backendBaseURL}/postrePaymentDetails`;

    try {
      const response = await axios.post(apiUrl, formDataRepayment);
      // Show success message
      toast({
        position: "top",
        title: `${response.data.message}`,
        description: "Details Added Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      // Show error message
      console.error("Error:", error);
      toast({
        position: "top",
        title: `${error.response.data.message}`,
        description: "Fields are not valid",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoadingRepayment(false);
    }
  };

  const handleDeleteFinancerDetailsLimit = async (_id) => {
    try {
      await axios.delete(`${backendBaseURL}/deleteFinancerDetails/${_id}`);

      toast({
        position: "top",
        title: "Financer Details Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Financer Details",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const calculateUtilizedLimit = (financerName) => {
    const filteredRecords = fetchfinanceGenerationNamesList.filter(
      (record) => record.financerOrganizationName === financerName
    );

    const sum = filteredRecords.reduce((acc, record) => {
      return acc + parseFloat(record.exporterInvoiceValue || 0);
    }, 0);

    return sum;
  };

  const calculateAmountInINR = (financerName) => {
    const filteredRecords = fetchrePaymentList.filter(
      (record) => record.financerOrganizationName === financerName
    );

    const sum = filteredRecords.reduce((acc, record) => {
      return acc + parseFloat(record.totalAmount || 0);
    }, 0);

    return sum;
  };

  const {
    isOpen: isModalOneOpen,
    onOpen: onModalOneOpen,
    onClose: onModalOneClose,
  } = useDisclosure();

  // Modal 2 state
  const {
    isOpen: isModalTwoOpen,
    onOpen: onModalTwoOpen,
    onClose: onModalTwoClose,
  } = useDisclosure();

  // Handlers to open specific modals
  const openModalOne = () => {
    setOverlay(<OverlayOne />);
    onModalOneOpen();
  };

  const openModalTwo = () => {
    setOverlay(<OverlayTwo />);
    onModalTwoOpen();
  };

  const handleOpenRepaymentModal = (el) => {
    setFormDataRepayment({
      financerOrganizationName: el.financerOrganizationName,
      transactionRefNo: el.transactionRefNo,
      repaymentCurrency: el.financeCurrency,
      netAmountPayable: el.exporterInvoiceValue, // Assuming this is the net amount payable
    });
    openModalTwo(); // Open the modal
  };

  const OverlayOne = () => (
    <ModalOverlay
      // bg="blackAlpha.300"
      // backdropFilter="blur(10px) hue-rotate(90deg)"
      bg="none"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      // backdropFilter="auto"
      // backdropInvert="80%"
      // backdropBlur="2px"
    />
  );

  const [overlay, setOverlay] = React.useState(<OverlayOne />);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();
  const ButtonStyle = {
    size: "xs",
    fontWeight: "bold",
    backgroundColor: "white",
    _hover: { backgroundColor: "white" },
  };
  const FormLabelStyle = {
    color: "gray",
    fontSize: "12px",
  };

  const InputStyle = {
    width: { base: "100%", md: "70%", lg: "550px" },
    focusBorderColor: "teal.400",
  };

  const SubInputStyle = {
    width: { base: "100%", md: "70%", lg: "270px" },
    focusBorderColor: "teal.400",
  };

  const [formData, setFormData] = useState({
    financerOrganizationName: "",
    transactionRefNo: "",
    salesContractNumber: "",
    exporterName: "",
    exporterInvoiceValue: "",
    exporterInvoiceValues: [],
    invoiceDate: "",
    exporterInvoiceNo: "",
    financeCurrency: "",
    creditTakenDate: "",
    creditDueDate: "",
    creditPeriod: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };

      // Calculate `creditDueDate` if both `creditTakenDate` and `creditPeriod` are available
      if (name === "creditTakenDate" || name === "creditPeriod") {
        const creditTakenDate = new Date(updatedData.creditTakenDate);
        const creditPeriod = parseInt(updatedData.creditPeriod) || 0;

        if (!isNaN(creditTakenDate.getTime()) && creditPeriod > 0) {
          creditTakenDate.setDate(creditTakenDate.getDate() + creditPeriod);
          updatedData.creditDueDate = creditTakenDate
            .toISOString()
            .split("T")[0]; // Format as YYYY-MM-DD
        }
      }

      return updatedData;
    });
  };

  const handleDeleteFinancerDetails = async (_id) => {
    try {
      await axios.delete(
        `${backendBaseURL}/deleteFinanceGenerationDetails/${_id}`
      );
      onClose();
      toast({
        position: "top",
        title: "Financer Details Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Deleting Financer Details",
        description: `${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const filterDetailsBySalesContractNumber = (salesContractNumber) => {
    const detail = shippingDetailsData.find(
      (detail) => detail.salesContractNumber === salesContractNumber
    );
    if (detail) {
      const exporterName = detail.shipmentWiseData.exporterName
        ? detail.shipmentWiseData.exporterName
        : detail.shipmentWiseData.hssName
        ? detail.shipmentWiseData.hssName
        : "";
      const invoiceDate = detail.shipmentWiseData?.exporterInvoiceDate
        ? new Date(
            detail.shipmentWiseData.exporterInvoiceDate
          ).toLocaleDateString("en-CA")
        : "";
      const exporterInvoiceNo = detail.shipmentWiseData?.exporterInvoiceNo
        ? detail.shipmentWiseData?.exporterInvoiceNo
        : "";

      const exporterInvoiceValues = detail.rows.map(
        (row) => row?.invoiceValue || ""
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        exporterName,
        invoiceDate,
        exporterInvoiceNo,
        exporterInvoiceValues,
      }));
    }
  };

  useEffect(() => {
    if (formData.salesContractNumber) {
      filterDetailsBySalesContractNumber(formData.salesContractNumber);
    }
  }, [formData.salesContractNumber]);

  const [selectedValues, setSelectedValues] = useState([]);
  const [sum, setSum] = useState(0);

  const handleCheckboxChange = (value) => {
    setSelectedValues((prev) => {
      const updatedValues = prev.includes(value)
        ? prev.filter((v) => v !== value)
        : [...prev, value];
      const newSum = updatedValues.reduce((acc, curr) => acc + Number(curr), 0); // Convert to number here
      setSum(newSum);
      setFormData((prevFormData) => ({
        ...prevFormData,
        exporterInvoiceValue: newSum,
      }));
      return updatedValues;
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    setIsLoading(true);
    let apiUrl = `${backendBaseURL}/postFinanceGenerationDetails`;

    try {
      const response = await axios.post(apiUrl, formData);
      //console.log("Response:", response.data);
      toast({
        position: "top",
        title: `${response.data.message}`,
        description: "Details Added Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error:", error);
      toast({
        position: "top",
        title: `${error.response.data.message}`,
        description: "Fields are not valid",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const InputStyleEdit = {
    borderRadius: "md",
    borderWidth: "2px",
    borderColor: "gray.300",
    py: "2",
    px: "4",
    variant: "unstyled",
  };

  const BoxStyle = {
    position: "absolute",
    top: "-8px",
    left: "8px",
    pointerEvents: "none",
    color: "teal",
    fontSize: "sm",
    bg: "white",
    px: "1",
  };

  const [selectedItem, setSelectedItem] = useState(null);
  // console.log("selected", selectedItem);
  const [editFinance, setEditFinance] = useState({
    financerOrganizationName: "",
    transactionRefNo: "",
    salesContractNumber: "",
    exporterName: "",
    exporterInvoiceValue: "",
    invoiceDate: "",
    financeCurrency: "",
    creditTakenDate: "",
    creditDueDate: "",
    creditPeriod: "",
  });

  const handleFinanceUpdate = async (_id) => {
    try {
      const apiEndpoint = `${backendBaseURL}/editFinanceGenerationDetails/${_id}`;
      const response = await axios.patch(apiEndpoint, editFinance);
      if (response.status !== 200) {
        throw new Error("Failed to update finance details");
      }
      setSelectedItem(null);
      setEditFinance({
        financerOrganizationName: "",
        transactionRefNo: "",
        salesContractNumber: "",
        exporterName: "",
        exporterInvoiceValue: "",
        invoiceDate: "",
        financeCurrency: "",
        creditTakenDate: "",
        creditDueDate: "",
        creditPeriod: "",
      });
      toast({
        position: "top",
        title: "Finance Details Updated Successfully",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast({
        position: "top",
        title: "Error Updating Finance Details",
        description: "An error occurred while updating the Finance Details.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditFinance = (el) => {
    setSelectedItem(el);
    setEditFinance({
      financerOrganizationName: el.financerOrganizationName,
      transactionRefNo: el.transactionRefNo,
      salesContractNumber: el.salesContractNumber,
      exporterName: el.exporterName,
      exporterInvoiceValue: el.exporterInvoiceValue,
      invoiceDate: el.invoiceDate,
      financeCurrency: el.financeCurrency,
      creditTakenDate: el.creditTakenDate,
      creditDueDate: el.creditDueDate,
      creditPeriod: el.creditPeriod,
    });
  };

  const formatDate = (date) => {
    if (!date) return "";
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  const filterFinanceList = () => {
    return financeListData.filter((financeItem) => {
      // Find matching item in calculateAmountDifference by transactionRefNo
      const matchingDifferenceItem = calculateAmountDifference().find(
        (differenceItem) =>
          differenceItem.transactionRefNo === financeItem.transactionRefNo
      );

      // If there's a matching transactionRefNo and the difference is 0, filter it out
      return !(
        matchingDifferenceItem && matchingDifferenceItem.difference === 0
      );
    });
  };

  // Filtered financeListData to be displayed
  const filteredFinanceList = filterFinanceList();

  //console.log("filteredFinanceList",filteredFinanceList);

  return (
    <Box
      pl={{ base: "2", md: "10" }}
      pt={{ base: "10", md: "10" }}
      w={{ base: "100%", md: "80%" }}
    >
      <Navbar />
      <br />

      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
      >
        <Box
          w={{ base: "100%", md: "100%" }}
          p={{ base: "5", md: "5" }}
          minH={750}
          borderRadius={10}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
        >
          <Flex justifyContent={"space-between"} p={5}>
            <Flex justifyContent={"flex-start"} gap={5}>
              <IoMdArrowRoundBack
                onClick={() => navigation("/home")}
                size={20}
                style={{ marginTop: 5 }}
              />
              <Heading textAlign={"start"} size={"md"} color={"teal.400"}>
                Finance Details
              </Heading>
            </Flex>
          </Flex>

          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent={{ base: "flex-start", md: "space-between" }}
          >
            <Box
              w={{ base: "100%", md: "100%" }}
              p={{ base: "5", md: "5" }}
              borderRadius={10}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
            >
              <TableContainer>
                <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                  <Thead>
                    <Tr boxShadow={"sm"}>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Name Of Financer
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Sanction Limit
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Utilized Limt
                      </Th>
                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "center", color: "teal" }}
                      >
                        Available Limit
                      </Th>

                      <Th
                        boxShadow={"sm"}
                        style={{ textAlign: "start", color: "teal" }}
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {contractSignedData.length > 0 &&
                      contractSignedData.map((el) => {
                        const utilizedLimit = calculateUtilizedLimit(
                          el.financerOrganizationName
                        );
                        const sanctionLimit = parseFloat(
                          el.limitOfFinance || 0
                        );
                        const amountInINR = calculateAmountInINR(
                          el.financerOrganizationName
                        );
                        const availableLimit = sanctionLimit - utilizedLimit;

                        return (
                          <Tr key={el._id} boxShadow={"md"}>
                            <Td style={{ textAlign: "center" }}>
                              <Button
                                {...ButtonStyle}
                                onClick={() => {
                                  setOverlay(<OverlayOne />);
                                  onOpen();
                                }}
                              >
                                {el.financerOrganizationName}
                              </Button>
                            </Td>
                            <Td style={{ textAlign: "center" }}>
                              <Button
                                {...ButtonStyle}
                                onClick={() => {
                                  setOverlay(<OverlayOne />);
                                  onOpen();
                                }}
                              >
                                {el.limitOfFinance}
                              </Button>
                            </Td>
                            <Td style={{ textAlign: "center" }}>
                              <Button
                                {...ButtonStyle}
                                onClick={() => {
                                  setOverlay(<OverlayOne />);
                                  onOpen();
                                }}
                              >
                                {utilizedLimit - amountInINR}
                              </Button>
                            </Td>
                            <Td style={{ textAlign: "center" }}>
                              <Button
                                {...ButtonStyle}
                                onClick={() => {
                                  setOverlay(<OverlayOne />);
                                  onOpen();
                                }}
                              >
                                {availableLimit + amountInINR}
                              </Button>
                            </Td>
                            <Td style={{ textAlign: "center" }}>
                              <Flex gap={3}>
                                {/* <FiEdit
                                  style={{ color: "teal", marginTop: 2 }}
                                  onClick={() => {
                                    setOverlay(<OverlayOne />);
                                    onOpen();
                                  }}
                                /> */}
                                <Popover>
                                  <PopoverTrigger>
                                    <Button
                                      size={"xs"}
                                      color="white"
                                      bgColor={"white"}
                                      _hover={{ bgColor: "white" }}
                                    >
                                      <MdDeleteForever
                                        style={{ color: "red" }}
                                        size={18}
                                      />
                                    </Button>
                                  </PopoverTrigger>
                                  <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody mt={5}>
                                      Are you sure you want to delete <br />
                                      financer limit?
                                    </PopoverBody>
                                    <PopoverFooter
                                      display="flex"
                                      justifyContent="flex-end"
                                      gap={2}
                                    >
                                      <ButtonGroup size="xs"></ButtonGroup>
                                      <ButtonGroup size="xs">
                                        <Button
                                          color="green"
                                          _hover={{
                                            color: "white",
                                            bgColor: "green",
                                          }}
                                          onClick={() =>
                                            handleDeleteFinancerDetailsLimit(
                                              el._id
                                            )
                                          }
                                          fontWeight={"bold"}
                                        >
                                          Yes
                                        </Button>
                                      </ButtonGroup>
                                    </PopoverFooter>
                                  </PopoverContent>
                                </Popover>
                              </Flex>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
                <Box>
                  {fetchfinancerNamesList.length === 0 && (
                    <Alert
                      status="info"
                      variant="subtle"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      mt={4}
                    >
                      <AlertIcon boxSize={8} />
                      No Record Found
                    </Alert>
                  )}
                </Box>
              </TableContainer>
              <Flex justifyContent={"flex-end"} mt={5}>
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPage={totalPage}
                />
              </Flex>
            </Box>
          </Flex>

          <Box
            w={{ base: "100%", md: "100%" }}
            p={{ base: "5", md: "5" }}
            mt={10}
            borderRadius={10}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"}
          >
            <Flex justifyContent={"space-between"} p={5}>
              <Flex justifyContent={"flex-start"} gap={5}>
                {/* <Heading
                  as="h6"
                  size="xs"
                  textAlign={"start"}
                  textDecoration={"underline"}
                >
                  Finance List
                </Heading> */}
                <Button
                  color="white"
                  backgroundColor="teal.400"
                  borderRadius="15"
                  onClick={() => navigate("/repayment")}
                  isLoading={isLoading}
                  _hover={{ backgroundColor: "green.200", color: "black" }}
                >
                  Repayment List
                </Button>
              </Flex>
              <Flex justifyContent={"flex-start"} gap={5}>
                <Button
                  color="white"
                  backgroundColor="teal.400"
                  borderRadius="15"
                  //onClick={handleSubmit}
                  // onClick={() => {
                  //   setOverlay(<OverlayOne />);
                  //   onOpen();
                  // }}
                  onClick={openModalOne}
                  isLoading={isLoading}
                  _hover={{ backgroundColor: "green.200", color: "black" }}
                >
                  Add Finance
                </Button>
                <Modal
                  isCentered
                  isOpen={isModalOneOpen}
                  onClose={onModalOneClose}
                  size={"xl"}
                >
                  {overlay}
                  <ModalOverlay />
                  <ModalContent maxW="70%">
                    {" "}
                    {/* Added maxW to prevent overflow */}
                    <ModalHeader>Add Finance</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <FormControl>
                        <Flex
                          justifyContent={"space-around"}
                          mt={5}
                          wrap="wrap"
                        >
                          <Box width={{ base: "100%", md: "45%" }}>
                            {" "}
                            {/* Adjusted box width */}
                            <FormLabel {...FormLabelStyle}>
                              Financer Name*
                            </FormLabel>
                            <ChakraSelect
                              name="financerOrganizationName"
                              value={formData.financerOrganizationName || ""}
                              onChange={handleInputChange}
                              {...InputStyle}
                            >
                              <option value="" disabled>
                                Financer Name
                              </option>
                              {financerNamesList.map((name) => (
                                <option key={name} value={name}>
                                  {name}
                                </option>
                              ))}
                            </ChakraSelect>
                          </Box>
                          <Box width={{ base: "100%", md: "45%" }}>
                            {" "}
                            {/* Adjusted box width */}
                            <FormLabel {...FormLabelStyle}>
                              Transaction Ref No
                            </FormLabel>
                            <Input
                              type="text"
                              name="transactionRefNo"
                              value={formData.transactionRefNo || ""}
                              onChange={handleInputChange}
                              {...InputStyle}
                            />
                          </Box>
                        </Flex>

                        <Flex
                          justifyContent={"space-around"}
                          mt={5}
                          wrap="wrap"
                        >
                          <Box width={{ base: "100%", md: "45%" }}>
                            {" "}
                            {/* Adjusted box width */}
                            <FormLabel {...FormLabelStyle}>
                              Sales Contract No*
                            </FormLabel>
                            <ChakraSelect
                              name="salesContractNumber"
                              value={formData.salesContractNumber || ""}
                              onChange={handleInputChange}
                              {...InputStyle}
                            >
                              <option value="" disabled>
                                Sales Contract No
                              </option>
                              {salesContractNumberList.map((name) => (
                                <option key={name} value={name}>
                                  {name}
                                </option>
                              ))}
                            </ChakraSelect>
                          </Box>
                          <Box width={{ base: "100%", md: "45%" }}>
                            {" "}
                            {/* Adjusted box width */}
                            <FormLabel
                              {...FormLabelStyle}
                              style={{ color: "green" }}
                            >
                              Exporter Name*
                            </FormLabel>
                            <Input
                              type="text"
                              name="exporterName"
                              value={formData.exporterName || ""}
                              onChange={handleInputChange}
                              {...InputStyle}
                              focusBorderColor="green"
                              borderColor={"green"}
                              bgColor={"green.100"}
                            />
                          </Box>
                        </Flex>

                        <Grid
                          templateColumns={{ base: "1fr", md: "1fr 1fr" }} // 1 column on small screens, 2 columns on medium+ screens
                          gap={5}
                          mt={5}
                          ml={8}
                        >
                          {/* Left side: Exporter Invoice Value and Total Invoice Value */}
                          <Box gridColumn={{ base: "1", md: "1" }}>
                            <Flex
                              direction={{ base: "column", md: "column" }}
                              gap={4}
                            >
                              {/* Exporter Invoice Value */}
                              <Box width="100%">
                                <FormLabel {...FormLabelStyle}>
                                  Exporter Invoice Value*
                                </FormLabel>
                                <Menu closeOnSelect={false}>
                                  <MenuButton
                                    as={Button}
                                    rightIcon={<ChevronDownIcon />}
                                    {...InputStyle}
                                  >
                                    Select Invoice Values
                                  </MenuButton>
                                  <MenuList>
                                    {formData.exporterInvoiceValues.map(
                                      (value, index) => (
                                        <MenuItem key={index}>
                                          <Checkbox
                                            isChecked={selectedValues.includes(
                                              value
                                            )}
                                            onChange={() =>
                                              handleCheckboxChange(value)
                                            }
                                          >
                                            {value}
                                          </Checkbox>
                                        </MenuItem>
                                      )
                                    )}
                                  </MenuList>
                                </Menu>
                              </Box>

                              {/* Total Invoice Value */}
                              <Box width="100%">
                                <FormLabel
                                  {...FormLabelStyle}
                                  style={{ color: "green" }}
                                >
                                  Total Invoice Value
                                </FormLabel>
                                <Input
                                  name="exporterInvoiceValue"
                                  value={formData.exporterInvoiceValue || sum}
                                  readOnly
                                  onChange={handleInputChange}
                                  {...InputStyle}
                                  focusBorderColor="green"
                                  borderColor={"green"}
                                  bgColor={"green.100"}
                                />
                              </Box>
                            </Flex>
                          </Box>

                          {/* Right side: Invoice Date and Invoice No */}
                          <Box gridColumn={{ base: "1", md: "2" }}>
                            <Flex
                              direction={{ base: "column", md: "column" }}
                              gap={4}
                            >
                              {/* Invoice Date */}
                              <Box width="100%">
                                <FormLabel
                                  {...FormLabelStyle}
                                  style={{ color: "green" }}
                                >
                                  Invoice Date
                                </FormLabel>
                                <Input
                                  type="date"
                                  name="invoiceDate"
                                  value={formData.invoiceDate || ""}
                                  onChange={handleInputChange}
                                  {...InputStyle}
                                  focusBorderColor="green"
                                  borderColor={"green"}
                                  bgColor={"green.100"}
                                />
                              </Box>

                              {/* Invoice No */}
                              <Box width="100%">
                                <FormLabel
                                  {...FormLabelStyle}
                                  style={{ color: "green" }}
                                >
                                  Invoice No
                                </FormLabel>
                                <Input
                                  name="exporterInvoiceNo"
                                  value={formData.exporterInvoiceNo || ""}
                                  readOnly
                                  onChange={handleInputChange}
                                  {...InputStyle}
                                  focusBorderColor="green"
                                  borderColor={"green"}
                                  bgColor={"green.100"}
                                />
                              </Box>
                            </Flex>
                          </Box>
                        </Grid>

                        <Flex
                          justifyContent={"space-around"}
                          mt={5}
                          wrap="wrap"
                        >
                          <Box width={{ base: "100%", md: "45%" }}>
                            <FormLabel {...FormLabelStyle}>Currency</FormLabel>
                            <ChakraSelect
                              name="financeCurrency"
                              value={formData.financeCurrency || ""}
                              onChange={handleInputChange}
                              {...InputStyle}
                            >
                              <option value="" disabled>
                                Currency
                              </option>
                              <option value="INR">INR</option>
                              <option value="USD">USD</option>
                              <option value="EUR">Euro</option>
                              <option value="QAR">Qatar Riyal</option>
                              <option value="SAR">Saudi Riyal</option>
                            </ChakraSelect>
                          </Box>
                          <Box width={{ base: "100%", md: "45%" }}>
                            <FormLabel {...FormLabelStyle}>
                              Credit Taken Date
                            </FormLabel>
                            <Input
                              type="date"
                              name="creditTakenDate"
                              value={formData.creditTakenDate || ""}
                              onChange={handleInputChange}
                              {...InputStyle}
                            />
                          </Box>
                        </Flex>

                        <Flex
                          justifyContent={"space-around"}
                          mt={5}
                          wrap="wrap"
                        >
                          <Box width={{ base: "100%", md: "45%" }}>
                            <FormLabel {...FormLabelStyle}>
                              Credit Period (in Days)
                            </FormLabel>
                            <Input
                              type="text"
                              name="creditPeriod"
                              value={formData.creditPeriod || ""}
                              onChange={handleInputChange}
                              {...InputStyle}
                            />
                          </Box>
                          <Box width={{ base: "100%", md: "45%" }}>
                            <FormLabel
                              {...FormLabelStyle}
                              style={{ color: "green" }}
                            >
                              Credit Due Date
                            </FormLabel>
                            <Input
                              type="date"
                              name="creditDueDate"
                              value={formData.creditDueDate || ""}
                              onChange={handleInputChange}
                              {...InputStyle}
                              focusBorderColor="green"
                              borderColor={"green"}
                              bgColor={"green.100"}
                            />
                          </Box>
                        </Flex>
                      </FormControl>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="white"
                        backgroundColor="teal.400"
                        borderRadius="15"
                        onClick={() => handleSubmit()}
                        isLoading={isLoading}
                        _hover={{
                          backgroundColor: "green.200",
                          color: "black",
                        }}
                      >
                        Save Finance
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </Flex>
            </Flex>
            <TableContainer>
              <Table variant="simple" colorScheme="teal" boxShadow={"md"}>
                <Thead>
                  <Tr boxShadow={"sm"}>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    ></Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Sales Contract No
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Financer Name
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Transaction Ref No
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Exporter Name
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Exporter Invoice Value
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Invoice Date
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Currency
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Credit Taken Date
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Credit Due Date
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Credit Period
                    </Th>
                    <Th
                      boxShadow={"sm"}
                      style={{ textAlign: "center", color: "teal" }}
                    >
                      Action
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {filteredFinanceList.length > 0 &&
                    filteredFinanceList?.map((el) => (
                      <Tr key={el._id} boxShadow={"md"}>
                        <Td style={{ textAlign: "center" }}>
                          <Button
                            {...ButtonStyle}
                            color="white"
                            backgroundColor="teal.400"
                            borderRadius="10"
                            _hover={{
                              backgroundColor: "green.200",
                              color: "black",
                            }}
                            //onClick={openModalTwo}
                            onClick={() => handleOpenRepaymentModal(el)}
                          >
                            Repay
                          </Button>
                          <Modal
                            isCentered
                            isOpen={isModalTwoOpen}
                            onClose={onModalTwoClose}
                          >
                            {overlay}
                            <ModalContent>
                              <ModalHeader>Repayment Form</ModalHeader>
                              <ModalCloseButton />
                              <ModalBody>
                                <FormControl>
                                  <Flex
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                    gap={4}
                                    mt={5}
                                  >
                                    <Box flex="1">
                                      <FormLabel {...FormLabelStyle}>
                                        Financer Name
                                      </FormLabel>
                                      <ChakraSelect
                                        name="financerOrganizationName"
                                        value={
                                          formDataRepayment.financerOrganizationName ||
                                          ""
                                        }
                                        onChange={handleInputChangeRepayment}
                                      >
                                        <option value="" disabled>
                                          Financer Name
                                        </option>
                                        {financerNamesList.map((name) => (
                                          <option key={name} value={name}>
                                            {name}
                                          </option>
                                        ))}
                                      </ChakraSelect>
                                    </Box>
                                    <Box flex="1">
                                      <FormLabel {...FormLabelStyle}>
                                        Transaction Ref No
                                      </FormLabel>
                                      <Input
                                        type="text"
                                        name="transactionRefNo"
                                        value={
                                          formDataRepayment.transactionRefNo ||
                                          ""
                                        }
                                        onChange={handleInputChangeRepayment}
                                      />
                                    </Box>
                                  </Flex>
                                  <Flex
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                    gap={4}
                                    mt={5}
                                  >
                                    <Box flex="1">
                                      <FormLabel {...FormLabelStyle}>
                                        Payment Through
                                      </FormLabel>
                                      <ChakraSelect
                                        name="paymentThrough"
                                        value={
                                          formDataRepayment.paymentThrough || ""
                                        }
                                        onChange={handleInputChangeRepayment}
                                      >
                                        <option value="" disabled>
                                          Bank Name
                                        </option>
                                        {bankNamesList.map((name) => (
                                          <option key={name} value={name}>
                                            {name}
                                          </option>
                                        ))}
                                      </ChakraSelect>
                                    </Box>
                                  </Flex>
                                  <Flex
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                    gap={4}
                                    mt={5}
                                  >
                                    <Box flex="1">
                                      <FormLabel {...FormLabelStyle}>
                                        Net Amount Payable
                                      </FormLabel>
                                      <Input
                                        type="text"
                                        name="netAmountPayable"
                                        value={
                                          formDataRepayment.netAmountPayable ||
                                          ""
                                        }
                                        onChange={handleInputChangeRepayment}
                                      />
                                    </Box>
                                    <Box flex="1">
                                      <FormLabel {...FormLabelStyle}>
                                        Additional Charges
                                      </FormLabel>
                                      <Input
                                        type="text"
                                        name="additionalCharges"
                                        value={
                                          formDataRepayment.additionalCharges ||
                                          ""
                                        }
                                        onChange={handleInputChangeRepayment}
                                      />
                                    </Box>
                                  </Flex>
                                  <Flex
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                    gap={4}
                                    mt={5}
                                  >
                                    <Box flex="1">
                                      <FormLabel {...FormLabelStyle}>
                                        Re-Payment Date
                                      </FormLabel>
                                      <Input
                                        type="date"
                                        name="repaymentDate"
                                        value={
                                          formDataRepayment.repaymentDate || ""
                                        }
                                        onChange={handleInputChangeRepayment}
                                      />
                                    </Box>
                                    <Box flex="1">
                                      <FormLabel {...FormLabelStyle}>
                                        Amount
                                      </FormLabel>
                                      <Input
                                        type="text"
                                        name="totalAmount"
                                        value={
                                          formDataRepayment.totalAmount || ""
                                        }
                                        onChange={handleInputChangeRepayment}
                                      />
                                    </Box>
                                  </Flex>
                                  <Flex
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                    gap={4}
                                    mt={5}
                                  >
                                    <Box flex="1">
                                      <FormLabel {...FormLabelStyle}>
                                        Currency
                                      </FormLabel>
                                      <ChakraSelect
                                        name="repaymentCurrency"
                                        value={
                                          formDataRepayment.repaymentCurrency ||
                                          ""
                                        }
                                        onChange={handleInputChangeRepayment}
                                      >
                                        <option value="" disabled>
                                          Currency
                                        </option>
                                        <option value="INR">INR</option>
                                        <option value="USD">USD</option>
                                        <option value="EUR">Euro</option>
                                        <option value="QAR">Qatar Riyal</option>
                                        <option value="SAR">Saudi Riyal</option>
                                      </ChakraSelect>
                                    </Box>
                                    <Box flex="1">
                                      <FormLabel {...FormLabelStyle}>
                                        Exchange Rate
                                      </FormLabel>
                                      <Input
                                        type="text"
                                        name="reExchangeRate"
                                        value={
                                          formDataRepayment.reExchangeRate || ""
                                        }
                                        onChange={handleInputChangeRepayment}
                                      />
                                    </Box>
                                  </Flex>

                                  <Flex
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                    gap={4}
                                    mt={5}
                                  >
                                    <Box flex="1">
                                      <FormLabel {...FormLabelStyle}>
                                        Amount In INR
                                      </FormLabel>
                                      <Input
                                        type="text"
                                        name="amountInINR"
                                        value={
                                          formDataRepayment.amountInINR || ""
                                        }
                                        onChange={handleInputChangeRepayment}
                                      />
                                    </Box>
                                    <Box flex="1">
                                      <FormLabel {...FormLabelStyle}>
                                        Bank Charges
                                      </FormLabel>
                                      <Input
                                        type="text"
                                        name="bankCharges"
                                        value={
                                          formDataRepayment.bankCharges || ""
                                        }
                                        onChange={handleInputChangeRepayment}
                                      />
                                    </Box>
                                  </Flex>
                                </FormControl>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="white"
                                  backgroundColor="teal.400"
                                  borderRadius="15"
                                  onClick={() => handleSubmitRepayment()}
                                  isLoading={isLoadingRepayment}
                                  _hover={{
                                    backgroundColor: "green.200",
                                    color: "black",
                                  }}
                                >
                                  Submit Re-Payment
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>
                            {el.salesContractNumber}
                          </Button>
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>
                            {el.financerOrganizationName}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>
                            {el.transactionRefNo}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.exporterName}</Button>
                        </Td>
                        {/* <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>
                            {el.exporterInvoiceValue}
                          </Button>
                        </Td> */}
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>
                            {amountDifferences.find(
                              (diff) =>
                                diff.transactionRefNo === el.transactionRefNo
                            )?.difference || el.exporterInvoiceValue}
                          </Button>
                        </Td>

                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>
                            {/* {el.invoiceDate} */}
                            {format(new Date(el.invoiceDate), "dd-MM-yyyy")}
                          </Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.financeCurrency}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.creditTakenDate}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.creditDueDate}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Button {...ButtonStyle}>{el.creditPeriod}</Button>
                        </Td>
                        <Td style={{ textAlign: "center" }}>
                          <Flex gap={3}>
                            <FiEdit
                              style={{ color: "teal", marginTop: 2 }}
                              onClick={() => {
                                onSidebarOpen();
                                handleEditFinance(el);
                              }}
                            />
                            <Popover>
                              <PopoverTrigger>
                                <Button
                                  size={"xs"}
                                  color="white"
                                  bgColor={"white"}
                                  _hover={{ bgColor: "white" }}
                                >
                                  <MdDeleteForever
                                    style={{ color: "red" }}
                                    size={18}
                                  />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverBody mt={5}>
                                  Are you sure you want to delete <br />
                                  financer?
                                </PopoverBody>
                                <PopoverFooter
                                  display="flex"
                                  justifyContent="flex-end"
                                  gap={2}
                                >
                                  <ButtonGroup size="xs"></ButtonGroup>
                                  <ButtonGroup size="xs">
                                    <Button
                                      color="green"
                                      _hover={{
                                        color: "white",
                                        bgColor: "green",
                                      }}
                                      onClick={() =>
                                        handleDeleteFinancerDetails(el._id)
                                      }
                                      fontWeight={"bold"}
                                    >
                                      Yes
                                    </Button>
                                  </ButtonGroup>
                                </PopoverFooter>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
              {selectedItem && (
                <Box>
                  <Drawer
                    isOpen={isSidebarOpen}
                    onClose={onSidebarClose}
                    placement="right"
                    size={"lg"}
                  >
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleFinanceUpdate(selectedItem._id);
                      }}
                    >
                      <DrawerOverlay />
                      <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Edit Finance Details</DrawerHeader>
                        <DrawerBody>
                          <Box position="relative" mb={5}>
                            <Select
                              name="financerOrganizationName"
                              value={editFinance.financerOrganizationName || ""}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  financerOrganizationName: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled>
                                Financer Name
                              </option>
                              {financerNamesList.map((financer) => (
                                <option key={financer} value={financer}>
                                  {financer}
                                </option>
                              ))}
                            </Select>
                            <Box {...BoxStyle}>Financer Name</Box>
                          </Box>

                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="text"
                              value={editFinance.transactionRefNo}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  transactionRefNo: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Transaction Ref No</Box>
                          </Box>
                          <br />
                          <Box position="relative" mb={5}>
                            <Select
                              name="salesContractNumber"
                              value={editFinance.salesContractNumber || ""}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  salesContractNumber: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled>
                                Sales Contract No
                              </option>
                              {salesContractNumberList.map((financer) => (
                                <option key={financer} value={financer}>
                                  {financer}
                                </option>
                              ))}
                            </Select>
                            <Box {...BoxStyle}>Sales Contract No</Box>
                          </Box>
                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="text"
                              value={editFinance.exporterName}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  exporterName: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Exporter Name</Box>
                          </Box>
                          <br />
                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="text"
                              value={editFinance.exporterInvoiceValue}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  exporterInvoiceValue: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Exporter Invoice Value</Box>
                          </Box>
                          <br />

                          <Box position="relative" mb={5}>
                            <Select
                              name="financeCurrency"
                              value={editFinance.financeCurrency || ""}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  financeCurrency: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled>
                                Currency
                              </option>
                              <option value="INR">INR</option>
                              <option value="USD">USD</option>
                              <option value="EUR">Euro</option>
                              <option value="QAR">Qatar Riyal</option>
                              <option value="SAR">Saudi Riyal</option>
                            </Select>
                            <Box {...BoxStyle}>Currency</Box>
                          </Box>

                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="text"
                              value={editFinance.creditPeriod}
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  creditPeriod: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Credit Period</Box>
                          </Box>
                          <br />
                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="date"
                              value={
                                editFinance.invoiceDate
                                  ? editFinance.invoiceDate.slice(0, 10)
                                  : ""
                              }
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  invoiceDate: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Invoice Date</Box>
                          </Box>
                          <br />
                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="date"
                              value={
                                editFinance.creditTakenDate
                                  ? editFinance.creditTakenDate.slice(0, 10)
                                  : ""
                              }
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  creditTakenDate: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Credit Taken Date</Box>
                          </Box>
                          <br />
                          <Box position="relative">
                            <Input
                              {...InputStyleEdit}
                              type="date"
                              value={
                                editFinance.creditDueDate
                                  ? editFinance.creditDueDate.slice(0, 10)
                                  : ""
                              }
                              onChange={(e) =>
                                setEditFinance({
                                  ...editFinance,
                                  creditDueDate: e.target.value,
                                })
                              }
                            />
                            <Box {...BoxStyle}>Credit Due Date</Box>
                          </Box>
                          <br />
                        </DrawerBody>
                        <DrawerFooter>
                          <Button
                            // onClick={handleFinanceUpdate}
                            type="submit"
                            bgGradient="linear(to-r, teal.300,  teal.100)"
                            _hover={{
                              bgGradient: "linear(to-r, teal.100,  teal.400)",
                            }}
                          >
                            Update Finance Details
                          </Button>
                        </DrawerFooter>
                      </DrawerContent>
                    </form>
                  </Drawer>
                </Box>
              )}
              <Box>
                {fetchfinanceGenerationNamesList.length === 0 && (
                  <Alert
                    status="info"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    mt={4}
                  >
                    <AlertIcon boxSize={8} />
                    No Record Found
                  </Alert>
                )}
              </Box>
            </TableContainer>
            <Flex justifyContent={"flex-end"} mt={5}>
              <Pagination
                page={pageList}
                setPage={setPageList}
                totalPage={totalPageList}
              />
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default FinanceForm;
