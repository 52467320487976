import { Flex } from '@chakra-ui/react'
import React from 'react'
import Sidebar from '../../../Components/Sidebar'
import RepaymentLists from './RepaymentLists'





const Repayment = () => {
  return (
    <Flex>
      <Sidebar />
      <RepaymentLists />
    </Flex>
  )
}

export default Repayment